import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import img from '../../assets/icons/dots.png'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import img3 from '../../assets/icons/Vector (9).png'
import img4 from '../../assets/icons/Vector (10).png'
import img5 from '../../assets/icons/Vector.png'
import AddUser from './AddUser';
import EditUser from './EditUser'
import DeleteUser from './DeleteUser'
import img7 from '../../assets/icons/Vector (13).png'
import Base_Url from '../../ApiUrl/ApiUrl';


const Users = () => {


  const [showEdit, setShowEdit] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [BankId, setBankId] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setBankId(id)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (id) => {

    setShowEdit(true)
  }


  const handleDelete = (id) => {
    setShowDelete(true)
  }


  const [data, setData] = useState([])
  const user = localStorage.getItem('token')

  const getData = async () => {

    await Base_Url.get('users', {
      headers: {
        Authorization: `Bearer ${user}`,
      }
    }).then((res) =>
      setData(res.data.users)
    )
  }


  useEffect(() => {
    getData()
  }, [])


  // handle access
  const userRole = JSON.parse(localStorage.getItem("CashFlow"));
console.log(userRole);
  return (
    <Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography variant='h6' sx={{ fontSize: "32px", fontWeight: "700", lineHeight: "43.58px", color: "#011E36" }}>Employers</Typography>
      </Box>






      <Grid container spacing={2}>


        {data? data.map((item) =>
          <Grid item sx={12} xl={3} lg={4} md={6} key={item.id}>

            <Box sx={{ width: '240px', height: '200px', backgroundColor: 'rgba(255, 255, 255, 1)', boxShadow: '1', borderRadius: '8px', padding: '32px, 48px, 32px, 48px', marginTop: '30px' }}>
              <Box sx={{ alignItems: 'end', justifyContent: 'end', display: 'flex', marginRight: '5px', paddingTop: '20px' }}>
              {userRole==='super_admin' || userRole==='admin' ?
                <Box >
                  <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => handleClick(e, item.id)
                    }

                  >

                    <img src={img} alt="" />

                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    {/* <MenuItem onClick={() => handleEdit(item.id)}>

                      <Box sx={{ display: 'flex' }} >
                        <Box sx={{ position: 'relative' }} >
                          <Box sx={{ width: '15.82px', height: '15.82px', borderRadius: '50px', backgroundColor: 'rgba(181, 199, 70, 1)' }}></Box>
                          <Box sx={{ position: 'absolute', top: '-4px', left: '1px' }}><img src={img3} alt="" /></Box>

                        </Box>

                        <Typography variant='h5' sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '19.07px', color: '#455E74', marginLeft: '8px' }}>Edit Employer</Typography>
                      </Box>
                    </MenuItem> */}


                    <MenuItem onClick={() => handleDelete(item.id)}>

                      <Box sx={{ display: 'flex' }}>
                        <Box sx={{ position: 'relative' }}>
                          <Box sx={{ width: '15.82px', height: '15.82px', borderRadius: '50px', backgroundColor: 'rgba(243, 66, 53, 1)' }}></Box>
                          <Box sx={{ position: 'absolute', top: '-4px', left: '3px' }} ><img src={img4} alt=""/></Box>

                        </Box>

                        <Typography variant='h5' sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '19.07px', color: '#455E74', marginLeft: '8px' }}>Delete Employer</Typography>
                      </Box>
                    </MenuItem>




                  </Menu>
                </Box>
  :null}

              </Box>








              <Box sx={{ width: '48px', height: '48px', marginTop: '0px', alignItems: 'center', marginLeft: '100px' }}>
                <img src={img5} alt=""  />
              </Box>


              <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>

                <Typography variant='h5' sx={{ fontSize: '16px', fontWeight: '400', lineHeight: '21.51px', color: 'rgba(1, 30, 54, 1)', marginTop: '15px', marginRight:'20px' }}>{item? item.name:''} </Typography>

              </Box>

              <Typography variant='h5' sx={{ fontSize: '18px', fontWeight: '700', lineHeight: '24,79px', color: 'rgba(1, 30, 54, 1)', marginTop: '15px', marginLeft: '60px' }}>{item? item.role.name:''} </Typography>


            </Box>
          </Grid>




        ) :'Loading......'}


{userRole=="super_admin" || userRole=="admin" ? 
        <Box sx={{ marginTop: "40px" }}>
          <Button onClick={() => setShowAdd(true)}>    <Box sx={{ width: "256px", height: "200px", boxShadow: '1', borderRadius: "8px", position: "relative" }}>

            <Box sx={{ width: '48px', height: '48px', backgroundColor: '#DAE2FF', borderRadius: '50px', marginLeft: '100px', position: 'absolute', top: '70px' }}>
              <img src={img7} style={{ marginTop: '12px', marginLeft: '1px' }} alt="" />

            </Box>

            <Typography variant='h6' sx={{ fontSize: '14px', fontWeight: '600', color: '#455E74', lineHeight: '19,07px', textTransform: 'capitalize', position: 'absolute', top: '140px', left: '80px' }}>Add New Employer</Typography>

          </Box>
          </Button>
        </Box> :''}





      </Grid>









      {showEdit && <EditUser setShowEdit={setShowEdit} showEdit={showEdit} BankId={BankId} getDataBank={getData} handleCloseMenu={handleClose} />}
      {showAdd && <AddUser setShowAdd={setShowAdd} showAdd={showAdd} getData={getData} />}
      {showDelete && <DeleteUser setShowDelete={setShowDelete} showDelete={showDelete} BankId={BankId} getData={getData} handleCloseMenu={handleClose} />}
      {/* {showCheck && <CheckCash setShowCheck={setShowCheck} showCheck={showCheck} id={BankId} getDataBank={getData} handleCloseMenu={handleClose} />} */}





    </Box>
  )
}

export default Users
