import styled from "@emotion/styled";
import {
  Backdrop,
  Box,
  Button,
FormControl, 
InputLabel,
 MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import img from "../../assets/icons/Vector (14).png";
import { useFormik } from "formik";
import * as Yup from "yup";
import Base_Url from "../../ApiUrl/ApiUrl";
import { toast } from "react-toastify";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
// import Select, { selectClasses } from '@mui/joy/Select';
import { Checkbox } from "@mui/joy";


const steps = ["Employer Info", "Employer Role", "Employer Permissions"];



const EditUser = ({ setShowEdit, showEdit, BankId, getDataBank, handleCloseMenu}) => {

  const handleClose = () => setShowEdit(false);
  const user = localStorage.getItem("token");



  const [selectedItems, setSelectedItems] = useState([]);

  // Dummy data for the example
  const items = [
    { id: 1, label: "Permission 1" },
    { id: 2, label: "Permission 2" },
    { id: 3, label: "Permission 3" },
    { id: 4, label: "Permission 4" },
  ];

  // Function to handle checkbox changes
  const handleCheckboxChange = (itemId) => {
    // Check if the item is already in the selectedItems array
    const isSelected = selectedItems.includes(itemId);

    // If it's selected, remove it; otherwise, add it
    if (isSelected) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      role_id: "",
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .max(20, "Employer Name must be 4 characters or more.")
        .required("Employer Name is required"),

      email: Yup.string().email("Invalid email").required("Email is required"),

      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),

      role_id: Yup.string().required("Employer Role is required"),
    }),

    onSubmit: (values) => {


      const formData = new FormData()

      formData.append("name", values.name )
      formData.append("email", values.email )
      formData.append("password", values.password )
      formData.append("role_id", values.role_id )
      selectedItems?.map((item)=>
      formData.append("permissions_id[]", item )
      )




      Base_Url.put(`users/${BankId}`, formData, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
        .then((res) => {
          toast.success("Employer Updated Successfully", {autoClose:500});
          getDataBank();
          setShowEdit(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    },
  });

  // handle Step user

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };


 












  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={showEdit}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "12px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "27,24px",
                color: "#011E36",
              }}
            >
              Edit New Employer
            </Typography>
            <Button
              onClick={handleClose}
              sx={{ width: "24px", height: "24px" }}
            >
              <img src={img} alt="" />
            </Button>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ width: "100%", marginTop: "35px" }}>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                      {/* Start Step One  */}
                      {activeStep === 0 && (
                        <>
                          <Box sx={{ marginTop: "25px" }}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "14px",
                                lineHeight: "19,07px",
                                color: "rgba(69, 94, 116, 1)",
                                fontWeight: "700",
                              }}
                            >
                              User Name
                            </Typography>
                            <Box
                              sx={{
                                width: "400px",
                                height: "48px",
                                padding: "16px, 24px, 16px, 24px",
                                border: "1px solid gba(189, 189, 189, 1) ",
                                borderRadius: "8px",
                              }}
                            >
                              <TextField
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                sx={{ marginTop: "10px" }}
                                fullWidth
                                id="name"
                                name="name"
                                placeholder="Enter the cash name"
                              />
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: "12px",
                                  lineHeight: "19,07px",
                                  color: "#ff0000",
                                  fontWeight: "700",
                                  marginY: "0px",
                                }}
                              >
                                {" "}
                                {formik.touched.name && formik.errors.name}
                              </Typography>
                            </Box>
                          </Box>

                          <Box sx={{ marginTop: "40px" }}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "14px",
                                lineHeight: "19,07px",
                                color: "rgba(69, 94, 116, 1)",
                                fontWeight: "700",
                              }}
                            >
                              {" "}
                              Email
                            </Typography>
                            <Box
                              sx={{
                                width: "400px",
                                height: "48px",
                                padding: "16px, 24px, 16px, 24px",
                                border: "1px solid gba(189, 189, 189, 1) ",
                                borderRadius: "8px",
                              }}
                            >
                              <TextField
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                sx={{ marginTop: "10px" }}
                                fullWidth
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Enter the email"
                              />
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: "12px",
                                  lineHeight: "19,07px",
                                  color: "#ff0000",
                                  fontWeight: "700",
                                  marginY: "0px",
                                }}
                              >
                                {" "}
                                {formik.touched.email && formik.errors.email}
                              </Typography>
                            </Box>
                          </Box>

                          <Box sx={{ marginTop: "40px" }}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "14px",
                                lineHeight: "19,07px",
                                color: "rgba(69, 94, 116, 1)",
                                fontWeight: "700",
                              }}
                            >
                              {" "}
                              Password
                            </Typography>
                            <Box
                              sx={{
                                width: "400px",
                                height: "48px",
                                padding: "16px, 24px, 16px, 24px",
                                border: "1px solid gba(189, 189, 189, 1) ",
                                borderRadius: "8px",
                              }}
                            >
                              <TextField
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                sx={{ marginTop: "10px" }}
                                fullWidth
                                id="password"
                                name="password"
                                type="password"
                                placeholder="Enter the password"
                              />
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: "12px",
                                  lineHeight: "19,07px",
                                  color: "#ff0000",
                                  fontWeight: "700",
                                  marginY: "0px",
                                }}
                              >
                                {" "}
                                {formik.touched.password &&
                                  formik.errors.password}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              marginLeft: "110px",
                              marginTop: "60px",
                              display: "flex",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={handleClose}
                              sx={{
                                width: "210px",
                                height: "43px",
                                borderRadius: "8px",
                                padding: "16px, 32px, 16px, 32px",
                                backgroundColor: "rgba(117, 119, 120, 1)",
                                color: "rgba(255, 255, 255, 1)",
                                boxShadow: "1",
                                textTransform: "capitalize",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              type="submit"
                              sx={{
                                width: "210px",
                                height: "43px",
                                borderRadius: "8px",
                                padding: "16px, 32px, 16px, 32px",
                                backgroundColor: "rgba(1, 30, 54, 1)",
                                color: "rgba(255, 255, 255, 1)",
                                boxShadow: "1",
                                marginLeft: "20px",
                                textTransform: "capitalize",
                              }}
                              onClick={handleNext}
                            >
                              Next
                            </Button>
                          </Box>
                        </>
                      )}

                      {/* End Step Two  */}
                      {activeStep === 1 && (
                        <>
                          <Box sx={{ marginTop: "30px" }}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "14px",
                                lineHeight: "19,07px",
                                color: "rgba(69, 94, 116, 1)",
                                fontWeight: "700",
                              }}
                            >
                              {" "}
                              Employer Role
                            </Typography>
                            <FormControl
                              sx={{
                                width: "400px",
                                height: "48px",
                                borderRadius: "8px",
                                marginTop: "12px",
                              }}
                            >
                              <InputLabel id="select-label">Role</InputLabel>
                              <Select
                                labelId="select-label"
                                id="role_id"
                                value={formik.values.role_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label="Role"
                                name="role_id"
                              >
                                <MenuItem value="1">
                                  Accountant Manager
                                </MenuItem>
                                <MenuItem value="2">Admin </MenuItem>
                                <MenuItem value="3"> Empolyee</MenuItem>
                              </Select>
                            </FormControl>
                            {formik.touched.role_id && formik.errors.role_id ? (
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: "14px",
                                  lineHeight: "19,07px",
                                  color: "#ff0000",
                                  fontWeight: "700",
                                  marginTop: "15px",
                                }}
                              >
                                {" "}
                                {formik.errors.role_id}{" "}
                              </Typography>
                            ) : (
                              ""
                            )}
                          </Box>

                          <Box
                            sx={{
                              marginLeft: "110px",
                              marginTop: "260px",
                              display: "flex",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={handleBack}
                              sx={{
                                width: "210px",
                                height: "43px",
                                borderRadius: "8px",
                                padding: "16px, 32px, 16px, 32px",
                                backgroundColor: "rgba(117, 119, 120, 1)",
                                color: "rgba(255, 255, 255, 1)",
                                boxShadow: "1",
                                textTransform: "capitalize",
                              }}
                            >
                              Pervious
                            </Button>
                            <Button
                              variant="contained"
                              type="submit"
                              sx={{
                                width: "210px",
                                height: "43px",
                                borderRadius: "8px",
                                padding: "16px, 32px, 16px, 32px",
                                backgroundColor: "rgba(1, 30, 54, 1)",
                                color: "rgba(255, 255, 255, 1)",
                                boxShadow: "1",
                                marginLeft: "20px",
                                textTransform: "capitalize",
                              }}
                              onClick={handleNext}
                            >
                              Next
                            </Button>
                          </Box>
                        </>
                      )}
                      {activeStep === 2 && (
                        <>
                          <Box sx={{ marginTop: "20px" }}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "15px",
                                fontWeight: "600",
                                lineHeight: "27,24px",
                                color: "#455E74",
                              }}
                            >
                              Choose all permissions for new employer
                            </Typography>

                            <Box sx={{ marginTop: "30px" }}>
                              {items.map((item) => (
                                <Box sx={{ marginTop: "13px" }} key={item.id}>
                                  <Checkbox
                                    label={item.label}
                                    variant="soft"
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() =>
                                      handleCheckboxChange(item.id)
                                    }
                                  />
                                </Box>
                              ))}
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              marginLeft: "110px",
                              marginTop: "60px",
                              display: "flex",
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                width: "210px",
                                height: "43px",
                                borderRadius: "8px",
                                padding: "16px, 32px, 16px, 32px",
                                backgroundColor: "rgba(117, 119, 120, 1)",
                                color: "rgba(255, 255, 255, 1)",
                                boxShadow: "1",
                                textTransform: "capitalize",
                              }}
                              onClick={handleBack}
                            >
                              Pervious
                            </Button>
                            <Button
                              variant="contained"
                              type="submit"
                              sx={{
                                width: "210px",
                                height: "43px",
                                borderRadius: "8px",
                                padding: "16px, 32px, 16px, 32px",
                                backgroundColor: "rgba(1, 30, 54, 1)",
                                color: "rgba(255, 255, 255, 1)",
                                boxShadow: "1",
                                marginLeft: "20px",
                                textTransform: "capitalize",
                              }}
                              // onClick={handleNext}
                            >
                              Edit New Employer
                            </Button>
                          </Box>
                        </>
                      )}
                      {/* Step {activeStep + 1} */}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      {/* <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button> */}
                      <Box sx={{ flex: "1 1 auto" }} />
                      {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                        Next
                      </Button> */}
                      {/* {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-block" }}
                          >
                            Step {activeStep + 1} already completed
                          </Typography>
                        ) : (
                          <Button onClick={handleComplete}>
                            {completedSteps() === totalSteps() - 1
                              ? "Finish"
                              : "Complete Step"}
                          </Button>
                        ))} */}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>

            {/* <Box
              sx={{ marginLeft: "110px", marginTop: "60px", display: "flex" }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  width: "210px",
                  height: "43px",
                  borderRadius: "8px",
                  padding: "16px, 32px, 16px, 32px",
                  backgroundColor: "rgba(117, 119, 120, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  boxShadow: "1",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: "210px",
                  height: "43px",
                  borderRadius: "8px",
                  padding: "16px, 32px, 16px, 32px",
                  backgroundColor: "rgba(1, 30, 54, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  boxShadow: "1",
                  marginLeft: "20px",
                  textTransform: "capitalize",
                }}
              >
                Add Cash
              </Button>
            </Box> */}
          </form>
        </Box>
      </StyledModal>
    </div>  )
}

export default EditUser






const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "589px",
  height: "620px",
  borderRadius: "8px",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === "dark" ? "#000" : "#383838"
  }`,
});
