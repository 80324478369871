import React from 'react'
import icon from '../images/icon3.svg'
import icon2 from '../images/icon33.svg'
import icon3 from '../images/icon333.svg'

const IconExpense = () => {
  return (
    <div style={{position:"relative"}}>
      <img style={{position:"absolute", top:"14px", left:"-15px"}} src={icon} alt=''/>
      <img style={{position:"absolute", top:"0", left:"0"}} src={icon2} alt=''/>
      <img style={{position:"absolute", top:"7px", left:"10px"}}  src={icon3} alt=''/>
    </div>
  )
}

export default IconExpense
