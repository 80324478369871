import React from 'react'
import Register from '../components/Register/Register'

const RegisterPage = () => {
  return (
   <>
   <Register/>
   </>
  )
}

export default RegisterPage