import React from 'react';
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';





const Loading = () => {
  return (
    <div className="loading-container">
    <div className="loader"></div>
  </div>
  )
}

export default Loading