import React from 'react'
import Companies from '../components/Companies/Companies'
import { Box } from '@mui/material'

const CompaniesPage = () => {
  return (
    <Box>
      <Companies/>
    </Box>
  )
}

export default CompaniesPage
