import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DashboardTable from './DashboardTable'
import Base_Url from '../../ApiUrl/ApiUrl'
import IconNumber from '../../assets/icons/IconNumber'
import IconAccount from '../../assets/icons/IconAccount'
import IconExpense from '../../assets/icons/IconExpense'
import IconMouth from '../../assets/icons/IconMouth'

const Dashboard = () => {


  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentMonthIndex = currentMonth + 1;



  const [data, setData] = useState([])
  const user = localStorage.getItem('token')

  const getData = async () => {

    const res = await Base_Url.get('statistics', {
      headers: {
        Authorization: `Bearer ${user}`,

      }, params: {
        month: currentMonthIndex
      }
    })
    if (res.data) {
      console.log(res);
      setData(res.data)
      setTotal(res.data.projects.data.length)
    }
  }


  useEffect(() => {
    getData()
  }, [])

  const [total, setTotal] = useState(0)


  return (
    <>

      <div>
        <Box sx={{ paddingTop: '0' }}>

          <Typography variant='h5' sx={{ fontSize: "32px", lineHeight: "43.58px", color: "#011E36", fontWeight: "700", marginTop: "15px", marginLeft: "25px" }}>Dashboard</Typography>


          <Box sx={{ maxWidth: { xs: "500px", lg: '920px', xl: "1100px", md: "600px" } }}>
            <Grid container spacing={3}>



              <Grid item xs={3}>

                <Box sx={{ height: "199px", padding: "32px, 48px, 32px, 48px", borderRadius: "8px", backgroundColor: "#FFFFFF", boxShadow: "1", marginTop: "40px" }}>

                  <Box sx={{ height: "42px", marginLeft: "40px", paddingTop: "30px" }}>
                    <IconNumber />
                  </Box>

                  <Typography component='p' sx={{ fontSize: "14px", lineHeight: "19.07px", fontWeight: "600", color: "#5B6668", marginLeft: "40px", paddingTop: "50px" }}>Toatal Cash
                  </Typography>

                  <Typography variant='h5' sx={{ fontSize: "18px", lineHeight: "t38.13px", fontWeight: "700", color: "#011E36", marginLeft: "40px", paddingTop: "20px", marginBottom: "40px" }}>{data?.total_cash_this_month} SAR</Typography>


                </Box>

              </Grid>



              <Grid item xs={3}>

                <Box sx={{  height: "199px", padding: "32px, 48px, 32px, 48px", borderRadius: "8px", backgroundColor: "#FFFFFF", boxShadow: "1", marginTop: "40px" }}>

                  <Box sx={{  height: "42px", marginLeft: "40px", paddingTop: "30px" }}>
                    <IconAccount />
                  </Box>

                  <Typography component='p' sx={{ fontSize: "14px", lineHeight: "19.07px", fontWeight: "600", color: "#5B6668", marginLeft: "40px", paddingTop: "50px" }}>Total Account’s Balance

                  </Typography>

                  <Typography variant='h5' sx={{ fontSize: "18px", lineHeight: "t38.13px", fontWeight: "700", color: "#011E36", marginLeft: "40px", paddingTop: "20px", marginBottom: "40px" }}>{data?.total_bank_balance} SAR</Typography>


                </Box>

              </Grid>



              <Grid item xs={3}>

                <Box sx={{ height: "199px", padding: "32px, 48px, 32px, 48px", borderRadius: "8px", backgroundColor: "#FFFFFF", boxShadow: "1", marginTop: "40px" }}>

                  <Box sx={{ height: "42px", marginLeft: "40px", paddingTop: "30px" }}>
                    <IconExpense />
                  </Box>

                  <Typography component='p' sx={{ fontSize: "14px", lineHeight: "19.07px", fontWeight: "600", color: "#5B6668", marginLeft: "40px", paddingTop: "50px" }}>This Month Expenses


                  </Typography>

                  <Typography variant='h5' sx={{ fontSize: "18px", lineHeight: "t38.13px", fontWeight: "700", color: "#011E36", marginLeft: "40px", paddingTop: "20px", marginBottom: "40px" }}>{data?.total_expense} SAR</Typography>


                </Box>

              </Grid>


              <Grid item xs={3} >

                <Box sx={{  height: "199px", padding: "32px, 48px, 32px, 48px", borderRadius: "8px", backgroundColor: "#FFFFFF", boxShadow: "1", marginTop: "40px" }}>

                  <Box sx={{ height: "42px", marginLeft: "40px", paddingTop: "30px" }}>
                    <IconMouth />
                  </Box>

                  <Typography component='p' sx={{ fontSize: "14px", lineHeight: "19.07px", fontWeight: "600", color: "#5B6668", marginLeft: "40px", paddingTop: "50px" }}>This Month Income



                  </Typography>

                  <Typography variant='h5' sx={{ fontSize: "18px", lineHeight: "t38.13px", fontWeight: "700", color: "#011E36", marginLeft: "40px", paddingTop: "20px", marginBottom: "40px" }}>{data.total_income} SAR</Typography>


                </Box>

              </Grid>
            </Grid>

          </Box>




          <DashboardTable />
        </Box>
      </div>

    </>
  )
}

export default Dashboard
