import React from 'react'
import icon from '../images/Vector (4).png'
import icon1 from '../images/Vector (3).png'

const Logs = () => {
  return (
    <div style={{position:"relative"}}>
      
      <img src={icon} alt='Logs'/>
      <img src={icon1} style={{position:"absolute" , top:'5px', left:"-2px"}} alt='Logs'/>

    </div>
  )
}

export default Logs
