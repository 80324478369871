import React from 'react'
import BankAccounts from '../components/BankAccounts/BankAccounts'

const BankAccountsPage = () => {

  return (
    <div>
      <BankAccounts/>
    </div>
  )
}

export default BankAccountsPage
