import React from 'react'
import icon from './Vector (28).png'

const DashboardIcon = () => {
  return (
    <div>
      <img src={icon} alt='Icon Dashboard'/>
    </div>
  )
}

export default DashboardIcon
