import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, Typography } from "@mui/material";
import Base_Url from "../../ApiUrl/ApiUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: "#F6F6FB",
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.75),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "400px",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "text.headingColor",
  fontSize: "12px",

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `15px`,
    transition: theme.transitions.create("width"),
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));



export default function Navbar() {

  const user= localStorage.getItem('token')

const navigate=useNavigate()


const handleLogout=()=>{
  Base_Url.post('logout', null,{
    headers:{
      Authorization: `Bearer ${user}`
    }}
 ).then((res)=>{
  if(res.data){
    toast.success('Logout Successfully')
    localStorage.removeItem('token')
    navigate('/')
  }

 })
}

const currentDate = new Date();

// Get individual date components
const day = currentDate.getDate();
const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based months
const year = currentDate.getFullYear();

// Format the date as desired (e.g., "MM/DD/YYYY" or "YYYY-MM-DD")
const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;



  return (

      <AppBar
        position="static"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar >
          
         <Grid container spacing={2}>

<Grid item xs={3}  md={8} lg={8} xl={8}>
  <Box  >
    <Typography sx={{fontSize:'16px', lineHeight:'21.79px', fontWeight:'600', color:'#011E36'}}>{formattedDate}</Typography>
  </Box>
</Grid>



<Grid item xs={9}  md={8} lg={8} xl={8}>


<Box>
         <Button variant="contained" onClick={()=>handleLogout()} sx={{backgroundColor:'red', minWidth:'130px', height:'40px', fontSize:'18px',color:'white', textTransform:'capitalize', marginLeft:{xl:'920px',lg:'720px', md:'400px', xs:'100px'}, marginTop:{xl:'-70', md:'-70px', lg:'-70px', xs:'0px'}, marginBottom:'0px'}}>Logout</Button>

          </Box> 


</Grid>








         </Grid>


        








        </Toolbar>
      </AppBar>

  );
}
