import React from 'react'
import Dashboard from '../components/Dashboard/Dashboard'

const DashboardPage = () => {
  return (
    <div>
      <Dashboard/>
    </div>
  )
}

export default DashboardPage
