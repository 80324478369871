import { Box, Button, Fade, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "../../assets/icons/Vector (7).png";
import img1 from "../../assets/icons/dots.png";
import img2 from "../../assets/icons/Vector (8).png";
import img3 from "../../assets/icons/Vector (9).png";
import img4 from "../../assets/icons/Vector (10).png";
import img5 from "../../assets/icons/Vector (11).png";
import img6 from "../../assets/icons/Vector (12).png";
import img7 from "../../assets/icons/Vector (13).png";
import { Link } from "react-router-dom";
import EditCompanies from "./EditCompanies";
import AddCompanies from "./AddCompanies";
import DeleteCompanies from "./DeleteCompanies";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Base_Url from "../../ApiUrl/ApiUrl";
import AddProject from "../Projects/AddProject";
import AddProjectCompany from "../Projects/AddProjectCompany";

const Companies = () => {
  const user = localStorage.getItem("token");
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddProject, setShowAddProject] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setcompanyId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (id) => {
    setShowEdit(true);
  };

  const handleAddProject = (id) => {
    setShowAddProject(true);
  };

  const handleDelete = (id) => {
    setShowDelete(true);
  };

  const getData = async () => {
    const res = await Base_Url.get("companies", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const [data, setData] = useState([]);

  // const currentDate = new Date();

  // const year = currentDate.getFullYear();
  // const month = currentDate.getMonth() + 1;
  // const day = currentDate.getDate();

  // const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

  // console.log(formattedDate);

  const [companyId, setcompanyId] = useState(null);
  console.log(companyId);

  // handle access
  const userRole = JSON.parse(localStorage.getItem("CashFlow"));

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box sx={{ marginTop: "20px", display: "flex" }}>
        <Button sx={{ marginTop: "6px" }}>
          {" "}
          <Link to="/home">
            <img src={img} alt="" srcset="" />
          </Link>
        </Button>
        <Typography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "43.58px",
            color: "#011E36",
          }}
        >
          Companies
        </Typography>
      </Box>
      <Grid container spacing={2} >
        {data.map((item) => (
          <Grid item lg={4} xl={3} xs={12} md={6} key={item.id}>
            <Box sx={{ marginTop: "75px" }}>
              <Box
                sx={{
                  width: "256px",
                  height: "183px",
                  boxShadow: "1",
                  borderRadius: "8px",
                  padding: "32px, 48px, 32px, 48px",
                  // overflowX:"auto",
                  // overflowY:"hidden"
                }}
              >
                {/* <Button sx={{position:"absolute", top:"9px", right:'0'}} onClick={handleToggle}><img src={img1} alt="" srcset="" /></Button> */}

                  <Box
                    sx={{
                      alignItems: "end",
                      justifyContent: "end",
                      display: "flex",
                      marginRight: "5px",
                      paddingTop: "20px",
                    }}
                  >


{userRole === "super_admin" || userRole === "admin" ?                    <div>
                      <Button
                        id="fade-button"
                        aria-controls={open ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => handleClick(e, item.id)}
                      >
                        <img src={img1} alt="" srcset="" />
                      </Button>

                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                      >
                        <MenuItem onClick={() => handleAddProject(item.id)}>
                          <Link style={{ textDecoration: "none" }}>
                            <Box sx={{ display: "flex" }}>
                              <Box sx={{ position: "relative" }}>
                                <Box
                                  sx={{
                                    width: "15.82px",
                                    height: "15.82px",
                                    borderRadius: "50px",
                                    backgroundColor: "rgba(181, 199, 70, 1)",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "-4px",
                                    left: "2px",
                                  }}
                                >
                                  <img src={img3} alt="" srcset="" />
                                </Box>
                              </Box>

                              <Typography
                                variant="h5"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "19.07px",
                                  color: "#455E74",
                                  marginLeft: "8px",
                                  textDecoration: "none",
                                }}
                              >
                                Add New Project
                              </Typography>
                            </Box>
                          </Link>
                        </MenuItem>

                        <MenuItem onClick={() => handleEdit(item.id)}>
                          <Link style={{ textDecoration: "none" }}>
                            <Box sx={{ display: "flex" }}>
                              <Box sx={{ position: "relative" }}>
                                <Box
                                  sx={{
                                    width: "15.82px",
                                    height: "15.82px",
                                    borderRadius: "50px",
                                    backgroundColor: "rgba(181, 199, 70, 1)",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "-4px",
                                    left: "2px",
                                  }}
                                >
                                  <img src={img3} alt="" srcset="" />
                                </Box>
                              </Box>

                              <Typography
                                variant="h5"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "19.07px",
                                  color: "#455E74",
                                  marginLeft: "8px",
                                  textDecoration: "none",
                                }}
                              >
                                Edit Company
                              </Typography>
                            </Box>
                          </Link>
                        </MenuItem>

                        <MenuItem onClick={() => handleDelete(item.id)}>
                          {/* <MenuItem onClick={() => setShowDelete(true)}> */}

                          <Box sx={{ display: "flex" }}>
                            <Box sx={{ position: "relative" }}>
                              <Box
                                sx={{
                                  width: "15.82px",
                                  height: "15.82px",
                                  borderRadius: "50px",
                                  backgroundColor: "rgba(243, 66, 53, 1)",
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "-4px",
                                  left: "3px",
                                  width: "9.17px",
                                  height: "9.17px",
                                }}
                              >
                                <img
                                  style={{ width: "9.17px", height: "9.17px" }}
                                  src={img4}
                                  alt=""
                                  srcset=""
                                />
                              </Box>
                            </Box>

                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "19.07px",
                                color: "#455E74",
                                marginLeft: "8px",
                              }}
                            >
                              Delete Company
                            </Typography>
                          </Box>
                        </MenuItem>
                      </Menu>
                    </div> : null}






                  </Box>
                

                <Box sx={{ marginLeft: "60px", marginTop: "0px" }}>
                  <Box
                    sx={{
                      width: "48px",
                      height: "48px",
                      marginLeft: "10px",
                      marginTop: "0px",
                      position: "relative",
                    }}
                  >
                    <img src={img5} alt="" srcset="" />
                    <img
                      src={img6}
                      alt=""
                      srcset=""
                      style={{ position: "absolute", top: "18px", left: "6px" }}
                    />
                  </Box>

                  <Typography
                    component="p"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "19.07px",
                      color: "#5B6668",
                      paddingLeft: "0px",
                      paddingTop: "13px",
                    }}
                  >
                    {item.projects_count} Projects
                  </Typography>

                  <Box
                    sx={{ width: "900px", height: "90px", marginLeft: "-20px" , overflowX:"auto"}}
                  >
                    <Typography component="p">
                      <Link
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          lineHeight: "38.13px",
                          color: "#011E36",
                          textDecoration: "none",
                          paddingLeft: "20px",
                          marginTop: "0px",
                        }}
                        to={`/project/${item.id}`}
                      >
                        {item.name}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}

          <Grid item lg={4} xl={3} xs={12} md={6}>    
              {userRole === "super_admin" || userRole === "admin" ? (

            <Box sx={{ marginTop: "70px" }}>
              <Button onClick={() => setShowAdd(true)}>
                {" "}
                <Box
                  sx={{
                    width: "256px",
                    height: "183px",
                    boxShadow: "1",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "48px",
                      height: "48px",
                      backgroundColor: "#DAE2FF",
                      borderRadius: "50px",
                      marginLeft: "100px",
                      position: "absolute",
                      top: "55px",
                    }}
                  >
                    <img
                      src={img7}
                      style={{ marginTop: "12px", marginLeft: "1px" }}
                      alt=""
                      srcset=""
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#455E74",
                      lineHeight: "19,07px",
                      textTransform: "capitalize",
                      position: "absolute",
                      top: "125px",
                      left: "70px",
                    }}
                  >
                    Add New Company
                  </Typography>
                </Box>
              </Button>
            </Box> 
               ) : null}
          </Grid>
    
      </Grid>

      {showEdit && (
        <EditCompanies
          setShowEdit={setShowEdit}
          showEdit={showEdit}
          companyId={companyId}
          data={data}
          getData={getData}
          handleCloseMenu={handleClose}
        />
      )}
      {showAdd && (
        <AddCompanies
          setShowAdd={setShowAdd}
          showAdd={showAdd}
          data={data}
          getData={getData}
          handleCloseMenu={handleClose}
          id={companyId}
        />
      )}
      {showDelete && (
        <DeleteCompanies
          setShowDelete={setShowDelete}
          showDelete={showDelete}
          companyId={companyId}
          getData={getData}
          handleCloseMenu={handleClose}
        />
      )}
      {showAddProject && (
        <AddProjectCompany
          setShowAddProject={setShowAddProject}
          showAdd={showAddProject}
          id={companyId}
          handleCloseMenu={handleClose}
        />
      )}
    </Box>
  );
};
export default Companies;
