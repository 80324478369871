import React from 'react'
import icon from '../images/icon2.svg'
import icon1 from '../images/icon22.svg'

const IconAccount = () => {
  return (
    <div style={{position:'relative'}}>
      <img src={icon} alt=''/>
      <img style={{position:"absolute", top:"0px",left:'5px' }} src={icon1} alt=''/>
    </div>
  )
}

export default IconAccount
