import React from 'react'
import icon from '../images/Vector (4).png'
import icon1 from '../images/Vector (3).png'

const Logs = () => {




  return (
    <div style={{position:"relative"}} >
      
      <img src={icon} alt='Logs' style={{cursor:'pointer'}}/>
      <img src={icon1} style={{position:"absolute" , top:'12px', right:"3px"}} alt='Logs'/>

    </div>
  )
}

export default Logs
