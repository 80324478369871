import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import DashboardAddTable from "../Dashboard/DashboardAddTable";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Logs from "../../assets/icons/LogsDashboard";
import img1 from "../../assets/icons/Vector (21).png";
import img2 from "../../assets/icons/Vector (22).png";
import Base_Url from "../../ApiUrl/ApiUrl";
import img from "../../assets/icons/Vector (16).png";
import img22 from "../../assets/icons/Vector (17).png";
import img3 from "../../assets/icons/Vector (18).png";
import EditDashboard from "../Dashboard/EditDashboard";
import DeleteDashboard from "../Dashboard/DeleteDashboard";
import { toast } from "react-toastify";
import LogsPop from "../Dashboard/LogsPop";
import ImportExportIcon from "@mui/icons-material/ImportExport";
// import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns'; // Date adapter
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker'
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { format } from "date-fns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { FaSearch } from "react-icons/fa";
import { useParams } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(243, 243, 243, 1)    ",
    color: "rgba(124, 124, 124, 1)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const sortData = (data, orderBy, order) => {
  return data?.slice().sort((a, b) => {
    const aValue = new Date(a[orderBy]);
    const bValue = new Date(b[orderBy]);

    if (order === "asc") {
      return aValue - bValue;
    } else {
      return bValue - aValue;
    }
  });
};

const DashboardTable = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [company_id, setCompany] = useState(null);
  const [project_id, setProject] = useState(null);
  const [priority_level, setPriority] = useState(null);
  const [name, setName] = useState("");
  const [ids, setId] = useState(null);
  const [orderBy, setOrderBy] = useState("date");
  const [order, setOrder] = useState("asc");
  const [countPages, setCountPages] = useState(0);

  const handleEditShow = (id) => {
    setId(id);
    setShowEdit(true);
  };

  const handleSort = (property) => {
    const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const handleDeleteShow = (id) => {
    setId(id);
    setShowDelete(true);
  };

  const handleShow = (id) => {
    setId(id);
    setShowLogs(true);
  };

  const handleSearchChangeEvent = (e) => {
    setName(e.target.value);
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 32,
      position: "relative",
      backgroundColor: "#DCDCDC",
      border: "1px solid",
      borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
      fontSize: 16,
      width: "350px",
      padding: "5px 6px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetId) => {
    const draggedId = e.dataTransfer.getData("text/plain");
    const draggedItem = data.find((item) => item.id === Number(draggedId));
    const updatedData = data.filter((item) => item.id !== Number(draggedId));
    const targetIndex = data.findIndex((item) => item.id === Number(targetId));

    updatedData.splice(targetIndex, 0, draggedItem);
    setData(updatedData);
  };
  const user = localStorage.getItem("token");

  const [current_page, setCurrent_page] = useState(1);

  const handleChangePagination = (event, value) => {
    // console.log("valueCurrentPageOfSet ", valueCurrentPageOfSet)
    setCurrent_page(value);
    getData(value);
  };

  console.log(current_page);

  const getDataOneOnly = async () => {
    const res = await Base_Url.get("items", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: {
        page: data,
      },
    });
    if (res.data.body.items.data) {
      // setData(res.data.body.items.data);
      setCurrent_page(res.data.body.items.current_page);
      setCountPages(res.data.body.items.current_page);
    } else {
      setData([]);
    }
  };

  const {id}  = useParams();
   console.log("idd", id);
  const getData = async () => {
    // console.log("Current", data);
    const res = await Base_Url.get(`projects/${id}`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: {
        page: current_page,
      },
    });
    console.log("projectss", res.data);

    if (res.data.project.items) {
      setData(res.data.project.items);
    } else {
      setData([]);
    }
  };



  useEffect(() => {
     getDataOneOnly()
  getDataOptions();
   getData()

  }, []);

  const [dataFilterCompanies, setDataFilterCompanies] = useState([]);
  const [dataFilterprojects, setDataFilterprojects] = useState([]);
  const [dataFilterBanks, setDataFilterBanks] = useState([]);
  const getDataOptions = async () => {
    const res = await Base_Url.get("data", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    setDataFilterCompanies(res.data.companies);
    setDataFilterprojects(res.data.projects);
    setDataFilterBanks(res.data.banks);
  };

  const handleAprroved = (idApplroved) => {
    Base_Url.post(`item/approved/${idApplroved}`, null, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res) => {
      toast.success(`${res.data.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getDataOneOnly();
    });
  };

  const getDataFilter = async () => {
    await Base_Url.get("item/filter", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: {
        company_id: company_id,
        project_id: project_id,
        priority: priority_level,
        date_from: date_from,
        date_to: date_to,
      },
    })
      .then((res) => {
        if (res.data.items.length > 0) {
          setData(res.data.items);
        } else {
          toast.error("No Result Found");
        }
        console.log(res.data);
      })
      .catch((error) => {
        toast.error("No Result Found");
      });
  };

  const getDataSearch = async () => {
    await Base_Url.get("search_items", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: {
        name: name,
      },
    })
      .then((res) => {
        if (res.data.items.length > 0) {
          setData(res.data.items);
          toast.success("Search Successfully");
          setName("");
        } else {
          toast.error("No Result Found");
        }
      })
      .catch((error) => {
        toast.error("No Result");
      });
  };

  const handleClear = () => {
    toast.success("Clear Successfully");

    setPriority(null);
    setProject(null);
    setCompany(null);
    setDateFrom(null);
    setDateTo(null);
    setData([]);
    getDataOneOnly();
  };

  const [date_to, setDateTo] = useState(null);
  const [date_from, setDateFrom] = useState(null);

  const handleDateFromChange = (date) => {
    let dateFrom = new Date(date);

    // Get the year, month, and day from the Date object
    const year = dateFrom.getFullYear();
    const month = String(dateFrom.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0'
    const day = String(dateFrom.getDate()).padStart(2, "0");

    // Create the "yyyy-mm-dd" formatted string
    const formattedDate = `${year}-${month}-${day}`;
    setDateFrom(formattedDate);
  };

  const handleDateToChange = (date) => {
    let dateFrom = new Date(date);

    const year = dateFrom.getFullYear();
    const month = String(dateFrom.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0'
    const day = String(dateFrom.getDate()).padStart(2, "0");

    // Create the "yyyy-mm-dd" formatted string
    const formattedDate = `${year}-${month}-${day}`;
    setDateTo(formattedDate);
  };

  // handle access
  const userRole = JSON.parse(localStorage.getItem("CashFlow"));

  return (
    <Box>
      <Box
        sx={{
          marginTop: "50px",
          marginLeft: "0px",
          maxWidth: { xs: "900px", lg: "920px", xl: "1100px", md: "600px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6} xl={8}>
            <Box sx={{ position: "relative" }}>
              <div
                className="input-wrapper"
                style={{ width: "330px", marginTop: "6px" }}
              >
                <FaSearch id="search-icon" />
                <input
                  placeholder="Type to search..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <Button
                type="submit"
                onClick={() => getDataSearch()}
                sx={{
                  textTransform: "capitalize",
                  position: "absolute",
                  top: "2px",
                  left: { xl: "260px", lg: "250px", md: "280px", xs: "280px" },
                }}
              >
                search
              </Button>
            </Box>
          </Grid>

          {userRole === "super_admin" || userRole === "admin" ? (
            <Grid item xs={12} md={12} lg={6} xl={4}>
              <Box
                sx={{
                  marginTop: { xl: "0px", lg: "-4px", md: "23px", xs: "23px" },
                }}
              >
                <Button
                  onClick={() => setShow(true)}
                  variant="contained"
                  sx={{
                    backgroundColor: "#011E36",
                    width: "198px",
                    height: "48px",
                    padding: "16px, 32px, 16px, 32px",
                    borderRadius: "8px",
                    color: "#DAE2FF",
                    boxShadow: "1",
                    marginLeft: "80px",
                  }}
                  startIcon={<ControlPointRoundedIcon />}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#DAE2FF",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "21.79px",
                      textTransform: "capitalize",
                    }}
                  >
                    Add New Item
                  </Typography>
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>

      <Box sx={{ marginTop: "50px" }}>
        <Box
          sx={{
            width: { xs: "350px", lg: "920px", xl: "950px", md: "600px" },
            height: { xs: "450px", lg: "85px", xl: "85px", md: "190px" },
            backgroundColor: "#F2F8FF",
            borderRadius: "8px",
            display: "flex",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
              <Box sx={{ marginTop: "22px", marginLeft: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="datePickerEditStyle"
                    sx={{
                      height: "40px",
                      width: "110px",
                      paddingBottom: "40px",
                    }}
                    label="Date From"
                    onChange={handleDateFromChange}
                    value={date_from}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>

            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
              <Box sx={{ marginTop: "22px", marginLeft: "15px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="datePickerEditStyle"
                    label="Date To"
                    sx={{ height: "40px", paddingTop: "0", width: "110px" }}
                    onChange={handleDateToChange}
                    value={date_to}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>

            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
              <Box>
                <FormControl
                  sx={{
                    m: 2,
                    width: "95px",
                    marginTop: "22px",
                    borderRadius: "8px",
                    height: "20px",
                    border: "1px solid rgba(242, 248, 255, 1)",
                  }}
                  size="small"
                >
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      fontSize: "12px",
                      marginTop: "3px",
                      color: "rgba(69, 94, 116, 1)",
                    }}
                  >
                    Company
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="company_id"
                    name="company_id"
                    value={company_id}
                    label="Company"
                    onChange={(e) => setCompany(e.target.value)}
                  >
                    {dataFilterCompanies?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
              <Box>
                <FormControl
                  sx={{
                    m: 1,
                    width: "95px",
                    marginTop: "22px",
                    borderRadius: "8px",
                    height: "20px",
                    border: "1px solid rgba(242, 248, 255, 1)",
                  }}
                  size="small"
                >
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      fontSize: "12px",
                      marginTop: "3px",
                      color: "rgba(69, 94, 116, 1)",
                    }}
                  >
                    Project
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="project_id"
                    name="project_id"
                    value={project_id}
                    label="Projects"
                    onChange={(e) => setProject(e.target.value)}
                  >
                    {dataFilterprojects?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
              <Box>
                <FormControl
                  sx={{
                    m: 1,
                    width: "95px",
                    marginTop: {
                      xl: "22px",
                      lg: "22px",
                      md: "22px",
                      xs: "22px",
                    },
                    borderRadius: "8px",
                    height: "20px",
                    border: "1px solid rgba(242, 248, 255, 1)",
                    marginBottom: {
                      xl: "0px",
                      lg: "140px",
                      md: "0px",
                      xs: "0px",
                    },
                  }}
                  size="small"
                >
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      fontSize: "12px",
                      marginTop: "3px",
                      color: "rgba(69, 94, 116, 1)",
                    }}
                  >
                    Priority
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="priority_level"
                    value={priority_level || ""}
                    label="priority_level"
                    name="priority_level"
                    onChange={(e) => setPriority(e.target.value)}
                  >
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="low">Low</MenuItem>
                    <MenuItem value="high">High</MenuItem>{" "}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Box
              sx={{
                marginLeft: "30px",
                marginTop: { xl: "40px", lg: "40px", md: "40px", xs: "40px" },
                display: "flex",
              }}
            >
              <Grid item xs={6} md={3} xl={2.5}>
                <Button
                  variant="contained"
                  onClick={() => handleClear()}
                  type="submit"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "16.34px",
                    color: "rgba(69, 94, 116, 1)",
                    backgroundColor: "rgba(218, 226, 255, 1)",
                    width: "104px",
                    height: "32px",
                    borderRadius: "8px",
                    padding: "8px, 16px, 8px, 16px",
                  }}
                >
                  Clear Filters
                </Button>
              </Grid>

              <Grid item xs={6} md={3} xl={2}>
                <Button
                  variant="contained"
                  onClick={() => getDataFilter()}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "16.34px",
                    color: "rgba(218, 226, 255, 1)",
                    backgroundColor: "rgba(69, 94, 116, 1)",
                    width: "104px",
                    height: "32px",
                    borderRadius: "8px",
                    padding: "8px, 16px, 8px, 16px",
                    marginLeft: "70px",
                  }}
                >
                  Apply Filters
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Box>
        <TableContainer sx={{ marginTop: "30px", borderRadius: "10px" }}>
          <Table
            sx={{
              maxWidth: { xs: "300px", lg: "920px", xl: "1100px", md: "600px" },
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow sx={{ color: "rgba(124, 124, 124, 1)" }}>
                <StyledTableCell>Full Title</StyledTableCell>
                <StyledTableCell>Item Value</StyledTableCell>
                <StyledTableCell
                  active={orderBy === "date"}
                  direction={orderBy === "date" ? order : "asc"}
                >
                  Date
                  <ImportExportIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleSort("date")}
                  />
                </StyledTableCell>

                <StyledTableCell>Priority</StyledTableCell>
                <StyledTableCell>Total</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
                {/* <StyledTableCell>Logs</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortData(data, orderBy, order).map((item) => (
                <StyledTableRow
                  sx={{
                    backgroundColor: `${
                      item.status === "paid" ? "rgba(33, 150, 243, 1)" : ""
                    }`,
                  }}
                  draggable
                  onDragStart={(e) => handleDragStart(e, item.id)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, item.id)}
                  key={item.id}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ width: "200px" }}
                  >
                    <div class="scroll-container">
                      <p class="scroll-text">
                       
                         {item?.name} , {item?.description}
                      </p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.value >= 0 ? (
                      <img src={img1} alt="" srcset="" />
                    ) : (
                      <img src={img2} alt="" />
                    )}{" "}
                    {item?.value}
                  </StyledTableCell>
                  <StyledTableCell> {item?.date} </StyledTableCell>

                  <StyledTableCell>{item?.priority_level}</StyledTableCell>
                  <StyledTableCell>{item?.total}</StyledTableCell>

                  {userRole === "super_admin" || userRole === "admin" ? (
                    <StyledTableCell>
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          position: "relative",
                          padding: "16px, 8px, 16px, 8px",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "rgba(33, 150, 243, 1)",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            onClick={() => handleAprroved(item.id)}
                            style={{
                              position: "absolute",
                              top: "6px",
                              left: "4px",
                            }}
                            src={img}
                            alt=""
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: "rgba(174, 0, 167, 1)",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            onClick={() => handleEditShow(item.id)}
                            style={{
                              position: "absolute",
                              top: "4px",
                              left: "34px",
                            }}
                            src={img22}
                            alt=""
                          />
                        </Box>

                        {/* <Box>
        <Box sx={{backgroundColor:'rgba(174, 0, 167, 1)', borderRadius:'50%', width:'20px', height:'20px', marginLeft:'10px'}}>
            <Box><img style={{position:'absolute', top:'6px', left:'65px'}}  src={img4} alt=''/></Box>
            <Box sx={{display:'flex',position:'absolute', top:'9px', left:'67px' }}>
<Box sx={{ backgroundColor:'rgba(174, 0, 167, 1)', width:'1.5px', height:'1.5px', borderRadius:'50px'}}></Box>
<Box sx={{ backgroundColor:'rgba(174, 0, 167, 1)', width:'1.5px', height:'1.5px', marginLeft:'1px', borderRadius:'50px'}}></Box>
<Box sx={{ backgroundColor:'rgba(174, 0, 167, 1)', width:'1.5px', height:'1.5px' , borderRadius:'50px', marginLeft:'0.6px'}}></Box>
            </Box>
        </Box>

        </Box>
       */}

                        <Box
                          sx={{
                            backgroundColor: "rgba(243, 66, 53, 1)",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            onClick={() => handleDeleteShow(item.id)}
                            style={{
                              position: "absolute",
                              top: "3px",
                              left: "65px",
                            }}
                            src={img3}
                            alt=""
                          />
                        </Box>

                        <Box
                          sx={{ cursor: "pointer", marginLeft: "15px" }}
                          onClick={() => handleShow(item.id)}
                        >
                          <Logs />
                        </Box>
                      </Box>
                    </StyledTableCell>
                  ) : null}

                  {/* <StyledTableCell sx={{ cursor: 'pointer' }} onClick={() => handleShow(item.id)} ><Logs /></StyledTableCell> */}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          marginY: "30px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Pagination
          count={countPages}
          shape="circular"
          page={current_page}
          onChange={handleChangePagination}
          color="primary"
        />
      </Box>

      {showEdit && (
        <EditDashboard
          show={showEdit}
          setShow={setShowEdit}
          id={ids}
          companies={setDataFilterCompanies}
          projects={setDataFilterprojects}
          banks={setDataFilterBanks}
          getDataEdit={getData}
        />
      )}
      {show && (
        <DashboardAddTable
          show={show}
          setShow={setShow}
          getData={getDataOneOnly}
        />
      )}
      {showDelete && (
        <DeleteDashboard
          show={showDelete}
          setShow={setShowDelete}
          id={ids}
          getDataEdit={getDataOneOnly}
        />
      )}
      {showLogs && <LogsPop show={showLogs} setShow={setShowLogs} id={ids} />}
    </Box>
  );
};

export default DashboardTable;
