import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

function SidebarLinks({ title, path, icon }) {
  return (
    <NavLink to={path}>
      {({ isActive }) => (
        <Button
          disableRipple
          startIcon={icon}
          sx={{
            color: isActive ? "#DAE2FF" : "#455E74",
            backgroundColor: isActive && "#011E36" ,
            height: "54px",
            paddingLeft: "20px",
            width: {xs:"120px", lg:'170px', xl:"160px", md:"150px"},
            textTransform: "capitalize",
            justifyContent: "start",
            transition: "all 0.3s ease-in-out",
            borderRadius:"32px", 
            "&:hover, &:focus": {
              color: " #DAE2FF",
              backgroundColor: "#011E36",
            },
            "@media (max-width: 600px)": {
              width: "175px",
            },
          }}
        >
          {title}
        </Button>
      )}
    </NavLink>
  );
}

export default SidebarLinks;