import DashboardIcon from "../assets/icons/Dashboard";
import Bank from '../assets/icons/Bank'
import Logs from '../assets/icons/Logs'
import Companies from '../assets/icons/Companies'
import TodayTask from '../assets/icons/TodayTask'


const linksData = [
    {
      title: 'Dashboard',
      path: '/home',
      icon: <DashboardIcon />,
    },
    {
      title: 'Today Tasks',
      path: '/task',
      icon: <TodayTask />,
    },
    {
      title: 'Companies',
      path: '/companies',
      icon: <Companies />,
    },
    {
      title: 'Bank Accounts',
      path: '/bank',
      icon: <Bank />,
    },
    {
      title: 'Logs',
      path: '/logs',
      icon: <Logs />,
    },
    {
      title: 'Cash',
      path: '/cash',
      icon: <Bank />,

    },  
      {
      title: 'Employers',
      path: '/users',
      icon: <DashboardIcon />,
    },

  
  ];
  
  export default linksData;
  