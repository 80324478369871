import styled from '@emotion/styled';
import {  Backdrop, Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import img from "../../assets/icons/Vector (14).png"
import {useFormik } from 'formik';
import * as Yup from 'yup'
import { useParams } from 'react-router-dom';
import Base_Url from '../../ApiUrl/ApiUrl';
import { toast } from 'react-toastify';



const EditProject = ({setShowEdit, showEdit,  companyId, getData, handleCloseMenu}) => {


    const handleClose = () => setShowEdit(false);


    
const handleSubmit=()=>{
  Base_Url.put(`projects/${companyId}`, {name},{headers:{
    Authorization: `Bearer ${user}`,   
       }, params:{
        name:name
       }}).then((res)=>{
       
toast.success('Project Edited Successfully')
getData()
setShowEdit(false)
handleCloseMenu()
  }).catch((error)=>{
    console.log(error);
  })
}

    const user= localStorage.getItem('token')

    
  
     useEffect(() => {
      getDataProject()
   },[])


   const getDataProject=async()=>{

    const res= await Base_Url.get(`projects/${companyId}`, {headers:{
       Authorization: `Bearer ${user}`,
     }})
     setName(res.data.project.name)
   }
   
   
   const [name, setName] = useState([])

  
  
  
  


  return (
    <div>
    <StyledModal
aria-labelledby="unstyled-modal-title"
aria-describedby="unstyled-modal-description"
open={showEdit}
onClose={handleClose}
slots={{ backdrop: StyledBackdrop }}
>
<Box sx={style}>
<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'12px'}}>
<Typography variant='h5' sx={{fontSize:'20px', fontWeight:'700', lineHeight:'27,24px', color:'#011E36'}}>Edit Project</Typography>
<Button onClick={handleClose} sx={{width:'24px', height:'24px'}}><img src={img} alt=''/></Button>

</Box>



<Box sx={{marginTop:'40px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}>Project Name</Typography>
<Box sx={{width:'400px',height:'72px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
<TextField                     
          
        onChange={(e)=>setName(e.target.value)}    value={name}

sx={{marginTop:"10px"}} fullWidth label="Enter the project name" id="name" name='name'  />


</Box> 
</Box>





<Box sx={{marginLeft:'110px', marginTop:'20px', display:'flex'}}>
<Button variant="contained" onClick={handleClose}  sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(117, 119, 120, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', textTransform:"capitalize"}}>Cancel</Button>
<Button variant="contained" type='submit' onClick={()=>handleSubmit()} sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(1, 30, 54, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', marginLeft:'20px', textTransform:"capitalize"}}>Edit Project</Button>

</Box>








</Box>
</StyledModal>
</div>
  )
}

export default EditProject









const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "589px",
  height:"320px",
  borderRadius: '8px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});