import styled from '@emotion/styled';
import {  Backdrop, Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import img from "../../assets/icons/Vector (14).png"
import Base_Url from '../../ApiUrl/ApiUrl';
import { toast } from 'react-toastify';

const CheckCash = ({setShowCheck, showCheck,id, getDataBank, handleCloseMenu}) => {

  const handleClose = () => setShowCheck(false);

  const [data, setData]=useState([])
  const [current_balance, setCurrentData]=useState([])
  const [actual_balance, setActualData]=useState([])
  const [message, setMessage]=useState([])
  

  const user= localStorage.getItem('token')

  

  const getData=async()=>{
  
  const res=  await Base_Url.get(`cash_check_balance/${id}`, {headers:{
       Authorization: `Bearer ${user}`,
     }})
    //  setData(res.data)
     setData(res.data)
     setMessage(res.data.message)
     setCurrentData(res.data.bank.current_balance)
     setActualData(res.data.bank.actual_balance)
    
   }


   useEffect(() => {
    getData()
    },[])
 

const handleSubmit=()=>{
  
   Base_Url.patch(`cash_update_check_balance/${id}`, {actual_balance, current_balance},{
    headers:{
      Authorization: `Bearer ${user}`,
    },params:{
      actual_balance:actual_balance, 
      current_balance:current_balance
    }
  }).then((res)=>{
    if(res.data){
      toast.success(res.data.message)
      getData()
      // setShowCheck(false)
      handleCloseMenu()
    }
  })

}


  

const handleCloseModel=()=>{
  setShowCheck(false)
  getDataBank()
}








  return (
    <div>
          <StyledModal
aria-labelledby="unstyled-modal-title"
aria-describedby="unstyled-modal-description"
open={showCheck}
onClose={handleClose}
slots={{ backdrop: StyledBackdrop }}
>
<Box sx={style}>
<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'12px'}}>
<Typography variant='h5' sx={{fontSize:'20px', fontWeight:'700', lineHeight:'27,24px', color:'#011E36'}}>Check Account Balance</Typography>
<Button onClick={handleClose} sx={{width:'24px', height:'24px'}}><img src={img} alt=''/></Button>

</Box>





<Box sx={{marginTop:'40px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}>  Current Balance
</Typography>
<Box sx={{width:'400px',height:'48px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
<TextField    value={current_balance}
sx={{marginTop:"10px"}} fullWidth label="Current Balance" id="initial_balance" name='initial_balance'  />


</Box> 
</Box>



<Box sx={{marginTop:'30px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}> Actual balance

</Typography>
<Box sx={{width:'400px',height:'48px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
<TextField                     
          
        onChange={(e)=>setActualData(e.target.value)}   value={actual_balance} 

sx={{marginTop:"10px"}} fullWidth label="Actual balance" id="actual_balance"  name='actual_balance' />


</Box> 
</Box>



  <Button variant='contained' onClick={()=>handleSubmit()} sx={{backgroundColor:'rgba(1, 30, 54, 1)', width:'162px', height:'42px', borderRadius:'8px', textTransform:'capitalize', color:'rgba(218, 226, 255, 1)', marginTop:'70px'}}>
  Check Balance
  </Button>

<Box sx={{marginTop:'30px'}}>
<Typography variant='h6' sx={{fontSize:'14px', fontWeight:'700', lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)'}}>Balance difference</Typography>
<Box sx={{display:'flex'}}>
<Typography variant='h6' sx={{fontSize:'14px', fontWeight:'700', lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', marginTop:'15px'}}>$ </Typography>
<Typography variant='h6' sx={{fontSize:'14px', fontWeight:'700', lineHeight:'19,07px', color:'rgba(243, 66, 53, 1)', marginLeft:'7px', marginTop:'15px'}}>{data?.difference}</Typography>

</Box>
</Box>














<Box sx={{marginLeft:'110px', marginTop:'30px', display:'flex'}}>
<Button variant="contained" onClick={handleClose}  sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(117, 119, 120, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', textTransform:"capitalize"}}>Cancel</Button>
<Button variant="contained" type='submit' onClick={handleCloseModel} sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(1, 30, 54, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', marginLeft:'20px', textTransform:"capitalize"}}>Save Edits
</Button>

</Box>









</Box>
</StyledModal>
    </div>  )
}

export default CheckCash









const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "589px",
  height:"650px",
  borderRadius: '8px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});