import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "../../assets/icons/Illustration.png";
import { replace, useFormik } from "formik";
import * as Yup from "yup";
import Base_Url from "../../ApiUrl/ApiUrl";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";

const Login = ({ saveUser }) => {
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),

    onSubmit: (values) => {
      Base_Url.post("login", values)
        .then((res) => {
          if (res.data) {
            localStorage.setItem("CashFlow", JSON.stringify(res.data.role));
            localStorage.setItem("token", res.data.token.accessToken);
            saveUser();
            swal("Login Successfully!", "", "success");
            navigate("/home");
          }
        })
        .catch((error) => {
          //  console.log(error.response.data.message);
          toast.error("Failed Email or Password");
          console.log(error);
        });
    },
  });

  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        marginTop: "100px",
        marginX: "110px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item sx={12} md={12} lg={6} xl={6}>
          <Box sx={{ width: "519px", height: "591px" }}>
            <img src={img} alt="login" />
          </Box>
        </Grid>

        <Grid item sx={12} md={12} lg={6} xl={6}>
          <Box sx={{ marginTop: "110px", textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "800",
                lineHeight: "32.68px",
                color: "rgba(1, 30, 54, 1)",
                textAlign: "center",
              }}
            >
              Login
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <input
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                style={{
                  marginTop: "60px",
                  width: "416px",
                  height: "44px",
                  borderRadius: "16px",
                  border: "1px solid rgba(205, 205, 205, 1)",
                  padding: "0px 16px 0px 16px",
                }}
                placeholder="Email Or User Name"
              />
              <Typography
                component="p"
                sx={{
                  fontSize: "14px",
                  lineHeight: "19,07px",
                  color: "#ff0000",
                  fontWeight: "700",
                  marginTop: "15px",
                }}
              >
                {" "}
                {formik.touched.email && formik.errors.email}
              </Typography>
              <input
                type="password"
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                style={{
                  marginTop: "20px",
                  width: "416px",
                  height: "44px",
                  borderRadius: "16px",
                  border: "1px solid rgba(205, 205, 205, 1)",
                  padding: "0px 16px 0px 16px",
                }}
                placeholder="Password"
              />
              <Typography
                component="p"
                sx={{
                  fontSize: "14px",
                  lineHeight: "19,07px",
                  color: "#ff0000",
                  fontWeight: "700",
                  marginTop: "15px",
                }}
              >
                {" "}
                {formik.touched.password && formik.errors.password}
              </Typography>

<Box sx={{marginTop:'35px'}}>
  <Typography>
  Don't have an account ? <Link style={{textDecoration:'none', fontSize:'16px', fontWeight:'bold'}} to='/register'>Sign Up</Link>
  </Typography>
</Box>


              <button
                type="submit"
                style={{
                  marginTop: "40px",
                  width: "400px",
                  height: "51px",
                  borderRadius: "16px",
                  padding: "16px, 32px, 16px, 32px",
                  backgroundColor: "rgba(1, 30, 54, 1)",
                  color: "rgba(218, 226, 255, 1)",
                  boxShadow: "1",
                  marginLeft: "20px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Login
              </button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
