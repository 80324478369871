import { Box } from '@mui/material'
import React from 'react'
import img from '../../assets/images/bg_404 (1).png'

const NotFound = () => {
  return (
    <Box sx={{justifyContent:'center', display:'flex', alignItems:'center', marginTop:'150px'}}>
      <img src={img} alt="" srcset="" />
    </Box>
  )
}

export default NotFound
