import styled from "@emotion/styled";
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "../../assets/icons/Vector (14).png";

import Base_Url from "../../ApiUrl/ApiUrl";
import { toast } from "react-toastify";
import { Radio } from "@mui/joy";

const EditDashboard = ({ id, setShow, show, getDataEdit }) => {



  const [selectedValue, setSelectedValue] = React.useState("bank");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  console.log("Bank or Cash", selectedValue);

  const [name, setName] = useState(null);
  const [date, setDate] = useState(null);
  const [type, setType] = useState(null);
  const [comment, setComment] = useState(null);
  const [value, setValue] = useState(null);
  const [priority_level, setPriority_level] = useState(null);
  const [max_period, setMax_period] = useState("");
  const [description, setDescription] = useState(null);
  const [project_id, setProject_id] = useState(null);
  const [company_id, setCompany_id] = useState(null);
  const [transactionable_id, setTransactionableId] = useState(null);

  // handle change cash or bank

  const handleChangeCashOrBank =(e)=>{
    setTransactionableId(e.target.value)
  }


  const [data, setData] = useState([]);

  const getData = async () => {
    await Base_Url.get(`items/${id}`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res) => {
      setData(res.data.item);
      console.log("Data Item", res.data);
      setName(res.data.item.name);
      setDate(res.data.item.date);
      setType(res.data.item.type);
      setComment(res.data.item.comment);
      setValue(res.data.item.value);
      setPriority_level(res.data.item.priority_level);
      setMax_period(res.data.item.max_period);
      setDescription(res.data.item.description);
      setProject_id(res.data.item.project_id);
      setCompany_id(res.data.item.company_id);
      setTransactionableId(res.data.item.transactionable_id);
      setSelectedValue(res.data.type);
    });
  };


  

  useEffect(() => {
    getDataOptions();
    getData();
  }, []);

  const [dataFilterCompanies, setDataFilterCompanies] = useState([]);
  const [dataFilterprojects, setDataFilterprojects] = useState([]);
  const [dataFilterBanks, setDataFilterBanks] = useState([]);
  const [dataFilterCashes, setDataFilterCashes] = useState([]);

  const getDataOptions = async () => {
    const res = await Base_Url.get("data", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    setDataFilterCompanies(res.data.companies);
    //  setDataFilterprojects(res.data.projects)
    setDataFilterBanks(res.data.banks);
  };

  const getDataCashes = async () => {
    await Base_Url.get("cashes", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res) => setDataFilterCashes(res.data));
  };

  useEffect(() => {
    getDataCashes();
  }, []);

  const handleClose = () => setShow(false);
  const user = localStorage.getItem("token");

  const getProject = async (id) => {
    const res = await Base_Url.get(`companies/${id}`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    setDataFilterprojects(res.data.projects);
  };

  useEffect(() => {
    if (company_id) {
      getProject(company_id);
    }
  }, [company_id]);

  //   const formik = useFormik({
  //     initialValues:{
  //       name:'',
  //       date:'',
  //       type:'',
  //       value:'',
  //       priority_level:'',
  //       max_period:'',
  //       description:'',
  //       comment:'',
  //       bank_id:'',
  //       company_id:'',
  //       project_id:''
  //     },

  //     onSubmit:(values)=>{
  //       Base_Url.put(`items/${id}`, values,{headers:{
  //         Authorization: `Bearer ${user}`,
  //            }, params:{
  //               name:values.name,
  //               date:values.date,
  //               type:values.type,
  //               value:values.value,
  //               priority_level:values.priority_level,
  //               max_period:values.max_period,
  //               description:values.description,
  //               comment:values.comment,
  //               bank_id:values.bank_id,
  //               company_id:values.company_id,
  //               project_id:values.project_id
  //            }}).then((res)=>{
  //         if(res.data){
  // window.location.reload()
  //         }

  //       }).catch((error)=>{
  //         console.log(error);
  //       })

  //     }
  //   })

  // values for edit api dashboard

  const handleSubmit = () => {
    Base_Url.put(`items/${id}`, null, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: {
        name: name,
        date: date,
        type: type,
        value: value,
        priority_level: priority_level,
        max_period: max_period,
        description: description,
        comment: comment,
        company_id: company_id,
        project_id: project_id,
        transactionable_id: transactionable_id,
        transactionable_type: selectedValue,
      },
    })
      .then((res) => {
        toast.success("Item Edited Successfully");
        getDataEdit();
        setShow(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={show}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "12px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "27,24px",
                color: "#011E36",
              }}
            >
              Edit Item
            </Typography>
            <Button
              onClick={handleClose}
              sx={{ width: "24px", height: "24px" }}
            >
              <img src={img} alt="" />
            </Button>
          </Box>

          <Grid container spacing={1}>
            <Grid item lg="6" xl="6" xs="6" md="6">
              <Box sx={{ marginTop: "20px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  Item Name
                </Typography>
                <Box
                  sx={{
                    width: "400px",
                    height: "72px",
                    padding: "16px, 24px, 16px, 24px",
                    border: "1px solid gba(189, 189, 189, 1) ",
                    borderRadius: "8px",
                  }}
                >
                  <TextField
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    className="datePickerEditStyle"
                    sx={{ marginTop: "12px" }}
                    fullWidth
                    id="name"
                    name="name"
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: "0px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  Item Date
                </Typography>
                <Box
                  sx={{
                    width: "400px",
                    height: "72px",
                    padding: "16px, 24px, 16px, 24px",
                    border: "1px solid gba(189, 189, 189, 1) ",
                    borderRadius: "8px",
                  }}
                >
                  <TextField
                    className="datePickerEditStyle"
                    type="date"
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                    sx={{ marginTop: "12px" }}
                    fullWidth
                    id="date"
                    name="date"
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: "0px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  Date Type
                </Typography>
                <FormControl
                  sx={{
                    width: "400px",
                    height: "48px",
                    borderRadius: "8px",
                    marginTop: "12px",
                  }}
                >
                  <Select
                    labelId="select-label"
                    id="type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    label="Just Type..."
                    name="type"
                  >
                    <MenuItem value="once">Once</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ marginTop: "10px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  Maximum Period
                </Typography>
                <FormControl
                  sx={{
                    width: "400px",
                    height: "48px",
                    borderRadius: "8px",
                    marginTop: "12px",
                  }}
                  className="datePickerEditStyle"
                >
                  <Select
                    labelId="select-label"
                    id="max_period"
                    value={max_period}
                    onChange={(e) => setMax_period(e.target.value)}
                    label="7 Bays"
                    name="max_period"
                  >
                    <MenuItem value="1">1 Day</MenuItem>
                    <MenuItem value="2">2 Day</MenuItem>
                    <MenuItem value="3">3 Day</MenuItem>
                    <MenuItem value="4">4 Day</MenuItem>
                    <MenuItem value="5">5 Day</MenuItem>
                    <MenuItem value="6">6 Day</MenuItem>
                    <MenuItem value="7">7 Day</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ marginTop: "10px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  Comment
                </Typography>
                <Box
                  sx={{
                    width: "400px",
                    height: "143",
                    padding: "16px, 24px, 16px, 24px",
                    border: "1px solid gba(189, 189, 189, 1) ",
                    borderRadius: "8px",
                  }}
                >
                  <TextField
                    className="commentStyle"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    sx={{ width: "400px", height: "143px", marginTop: "10px" }}
                    id="comment"
                    label="Add comment"
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                    name="comment"
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item lg="6" xl="6" xs="6" md="6">
              <Box sx={{ marginTop: "25px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  Item Value
                </Typography>
                <Box
                  sx={{
                    width: "400px",
                    height: "72px",
                    padding: "16px, 24px, 16px, 24px",
                    border: "1px solid gba(189, 189, 189, 1) ",
                    borderRadius: "8px",
                  }}
                >
                  <TextField
                    className="datePickerEditStyle"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    sx={{ marginTop: "10px" }}
                    fullWidth
                    id="value"
                    name="value"
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: "-20px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  Company
                </Typography>
                <FormControl
                  sx={{
                    width: "400px",
                    height: "48px",
                    borderRadius: "8px",
                    marginTop: "12px",
                  }}
                  className="datePickerEditStyle"
                >
                  <Select
                    labelId="select-label"
                    id="company_id"
                    value={company_id || ""}
                    onChange={(e) => setCompany_id(e.target.value) || null}
                    label="Company"
                    name="company_id"
                  >
                    {/* <MenuItem value={dataFilterCompanies[0]?.id}>{dataFilterCompanies[0]?.name}</MenuItem> */}
                    {dataFilterCompanies?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ marginTop: "-1px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  Project
                </Typography>
                <FormControl
                  sx={{
                    width: "400px",
                    height: "48px",
                    borderRadius: "8px",
                    marginTop: "12px",
                  }}
                  className="datePickerEditStyle"
                >
                  <Select
                    labelId="select-label"
                    id="project_id"
                    value={project_id || ""}
                    onChange={(e) => setProject_id(e.target.value) || null}
                    label="Project"
                    name="project_id"
                  >
                    {dataFilterprojects?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>


              <Box sx={{ marginTop: "20px", display: "flex" }}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Radio
                        checked={selectedValue === "bank"}
                        onChange={handleChange}
                        value="bank"
                        name="radio-buttons"
                        slotProps={{ input: { "aria-label": "Bank" } }}
                        label="Bank"
                      />
                      <Radio
                        checked={selectedValue === "cash"}
                        onChange={handleChange}
                        value="cash"
                        name="radio-buttons"
                        slotProps={{ input: { "aria-label": "Cash" } }}
                        label="Cash"
                      />
                    </Box>
                  </Box>

                  {selectedValue === "bank" && (
                    <Box sx={{ marginTop: "15px" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "19,07px",
                          color: "rgba(69, 94, 116, 1)",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Bank Account
                      </Typography>
                      <FormControl
                        sx={{
                          width: "400px",
                          height: "48px",
                          borderRadius: "8px",
                          marginTop: "12px",
                        }}
                        className="datePickerEditStyle"
                      >
                        <InputLabel id="select-label">Bank Account</InputLabel>
                        <Select
                          labelId="select-label"
                          value={transactionable_id || ''}
                          onChange={handleChangeCashOrBank}
                          label="Bank Account"
                          id="transactionable_id"
                          name="transactionable_id"
                        >

                          {/* <MenuItem  value={dataFilterBanks[0]?.id}>{dataFilterBanks[0]?.name}</MenuItem> */}

                          {dataFilterBanks?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    </Box>
                  )}

                  {selectedValue === "cash" && (
                    <Box sx={{ marginTop: "15px" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "19,07px",
                          color: "rgba(69, 94, 116, 1)",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Cash Account
                      </Typography>
                      <FormControl
                        sx={{
                          width: "400px",
                          height: "48px",
                          borderRadius: "8px",
                          marginTop: "12px",
                        }}
                        className="datePickerEditStyle"
                      >
                        <InputLabel id="select-label">Cash Account</InputLabel>
                        <Select
                          labelId="select-label"
                          value={transactionable_id}
                          onChange={handleChangeCashOrBank}
                          label="Cash Account"
                          id="transactionable_id"
                          name="transactionable_id"
                        >
                          {/* <MenuItem  value={idBank}></MenuItem> */}

                          {/* <MenuItem  value={dataFilterBanks[0]?.id}>{dataFilterBanks[0]?.name}</MenuItem> */}

                          {dataFilterCashes?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    </Box>
                  )}

              <Box sx={{ marginTop: "10px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  Priority Level
                </Typography>
                <FormControl
                  sx={{
                    width: "400px",
                    height: "48px",
                    borderRadius: "8px",
                    marginTop: "12px",
                  }}
                  className="datePickerEditStyle"
                >
                  <Select
                    labelId="select-label"
                    id="priority_level"
                    value={priority_level || ""}
                    onChange={(e) => setPriority_level(e.target.value)}
                    name="priority_level"
                  >
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="low">Low</MenuItem>
                    <MenuItem value="high">High</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ marginTop: "10px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19,07px",
                    color: "rgba(69, 94, 116, 1)",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  Description
                </Typography>
                <Box
                  sx={{
                    width: "400px",
                    height: "72px",
                    padding: "16px, 24px, 16px, 24px",
                    border: "1px solid gba(189, 189, 189, 1) ",
                    borderRadius: "8px",
                  }}
                >
                  <TextField
                    className="datePickerEditStyle"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    sx={{ marginTop: "10px" }}
                    fullWidth
                    id="description"
                    name="description"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ marginLeft: "440px", marginTop: "10px", display: "flex" }}>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                width: "210px",
                height: "43px",
                borderRadius: "8px",
                padding: "16px, 32px, 16px, 32px",
                backgroundColor: "rgba(117, 119, 120, 1)",
                color: "rgba(255, 255, 255, 1)",
                boxShadow: "1",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={() => handleSubmit()}
              sx={{
                width: "210px",
                height: "43px",
                borderRadius: "8px",
                padding: "16px, 32px, 16px, 32px",
                backgroundColor: "rgba(1, 30, 54, 1)",
                color: "rgba(255, 255, 255, 1)",
                boxShadow: "1",
                marginLeft: "20px",
                textTransform: "capitalize",
              }}
            >
              Edit Item
            </Button>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
};

export default EditDashboard;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: { xl: "896px", lg: "896px" },
  height: { xl: "499px", lg: "499px" },
  borderRadius: "8px",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  // boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
  overflowY: "auto",
});
