import styled from '@emotion/styled';
import {  Backdrop, Box, Button, Modal, TextField, Typography } from '@mui/material';
import React from 'react'
import img from "../../assets/icons/Vector (14).png"
import {useFormik } from 'formik';
import * as Yup from 'yup'
import Base_Url from '../../ApiUrl/ApiUrl';
import { toast } from 'react-toastify';



const AddCash = ({setShowAdd, showAdd, getData, handleCloseMenu}) => {

  const handleClose = () => setShowAdd(false);
  const user= localStorage.getItem('token')


  const formik = useFormik({
    initialValues:{
      name:'',
      actual_balance:''
       },

    validationSchema: Yup.object({
      name: Yup.string()
        .max(20, "Cash Name must be 4 characters or more.")
        .required("Cash Name is required"),
        actual_balance:Yup.string().min(3, 'Must Be More 3').max(255).required(' Cash Balance Required')
    }),

    onSubmit:(values)=>{
      Base_Url.post('cashes', values,{headers:{
        Authorization: `Bearer ${user}`,   
           }}).then((res)=>{
  toast.success('Cash Added Successfully')
  getData()
  setShowAdd(false)
      }).catch((error)=>{
        toast.error(error.response.data.message);
      })

    }
  })





  


  return (
    <div>
          <StyledModal
aria-labelledby="unstyled-modal-title"
aria-describedby="unstyled-modal-description"
open={showAdd}
onClose={handleClose}
slots={{ backdrop: StyledBackdrop }}
>
<Box sx={style}>
<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'12px'}}>
<Typography variant='h5' sx={{fontSize:'20px', fontWeight:'700', lineHeight:'27,24px', color:'#011E36'}}>Add New Cash</Typography>
<Button onClick={handleClose} sx={{width:'24px', height:'24px'}}><img src={img} alt=''/></Button>

</Box>

<form onSubmit={formik.handleSubmit}>


<Box sx={{marginTop:'40px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}>Cash Name</Typography>
<Box sx={{width:'400px',height:'48px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
<TextField                     
          
        onChange={formik.handleChange} onBlur={formik.handleBlur}   value={formik.values.name}

sx={{marginTop:"10px"}} fullWidth  id="name" name='name' placeholder='Enter the cash name' />
<Typography component='p'  sx={{fontSize:"12px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700',marginY:'0px'  }}>    {formik.touched.name &&formik.errors.name}</Typography>


</Box> 
</Box>






<Box sx={{marginTop:'40px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}>  Cash Balance
</Typography>
<Box sx={{width:'400px',height:'48px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
<TextField                     
          
        onChange={formik.handleChange} onBlur={formik.handleBlur}   value={formik.values.actual_balance}

sx={{marginTop:"10px"}} fullWidth  id="actual_balance" name='actual_balance'  label='Enter Current Balance'/>
<Typography component='p'  sx={{fontSize:"12px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700',marginTop:'0px'  }}>    {formik.touched.initial_balance &&formik.errors.initial_balance}</Typography>


</Box> 
</Box>
















<Box sx={{marginLeft:'110px', marginTop:'60px', display:'flex'}}>
<Button variant="contained" onClick={handleClose}  sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(117, 119, 120, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', textTransform:"capitalize"}}>Cancel</Button>
<Button variant="contained" type='submit' sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(1, 30, 54, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', marginLeft:'20px', textTransform:"capitalize"}}>Add Cash
  
</Button>

</Box>



</form>





</Box>
</StyledModal>
    </div>
  )
}

export default AddCash








const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "589px",
  height:"520px",
  borderRadius: '8px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});