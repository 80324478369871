import styled from '@emotion/styled';
import { Backdrop, Box, Button, Modal, TextField, Typography } from '@mui/material';
import React from 'react'
import img from "../../assets/icons/Vector (14).png"
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Base_Url from '../../ApiUrl/ApiUrl';
import { toast } from 'react-toastify';




const ShareEmail = ({show, setShow, selectedIds}) => {

console.log(selectedIds);

  const handleClose = () => setShow(false);




const user= localStorage.getItem('token')



   const formik = useFormik({
    initialValues:{
            email:''
    },

    validationSchema: Yup.object({
      email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),    }),

    onSubmit:(values)=>{
       Base_Url.get('send_mail', {
        headers:{
          Authorization: `Bearer ${user}`
        }, params:{
          email:values.email ,
          ids:selectedIds
        }
      }).then((res)=>{
        toast.success(res.data.message)
        setShow(false)
        }).catch((error)=>{
toast.error('Please Select Id ')
        })
      }})



  // handle access
      const userRole = JSON.parse(localStorage.getItem("CashFlow"));


  return (
    <div>
    <StyledModal
aria-labelledby="unstyled-modal-title"
aria-describedby="unstyled-modal-description"
open={show}
onClose={handleClose}
slots={{ backdrop: StyledBackdrop }}
>
<form onSubmit={formik.handleSubmit}>
<Box sx={style}>
<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'12px'}}>
<Typography variant='h5' sx={{fontSize:'20px', fontWeight:'700', lineHeight:'27,24px', color:'#011E36'}}>Share Task(s) To Email</Typography>
<Button onClick={handleClose} sx={{width:'24px', height:'24px'}}><img src={img} alt=''/></Button>

</Box>


<Box sx={{marginTop:'40px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}> Email(s) Address</Typography>
<Box sx={{width:'400px',height:'72px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
<TextField                     
          
sx={{marginTop:"10px"}} fullWidth label="Add email address" id="email" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur}   value={formik.values.email} required/>


</Box> 
{formik.touched.email &&formik.errors.email ? <Typography component='p'  sx={{fontSize:"14px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'5px' }}>  {formik.errors.email} </Typography> :''}

</Box>



<Box sx={{marginLeft:'110px', marginTop:'30px', display:'flex'}}>
<Button variant="contained" onClick={handleClose}  sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(117, 119, 120, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', textTransform:"capitalize"}}>Cancel</Button>
<Button variant="contained" type='submit' sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(1, 30, 54, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', marginLeft:'20px', textTransform:"capitalize"}}>Submit & Send Email</Button>

</Box>








</Box>
</form>
</StyledModal>
</div>

  )
}

export default ShareEmail












const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "589px",
  height:"320px",
  borderRadius: '8px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});

