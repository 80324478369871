import styled from '@emotion/styled';
import { Autocomplete, Backdrop, Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import img from "../../assets/icons/Vector (14).png"
import {useFormik } from 'formik';
import * as Yup from 'yup'
import Base_Url from '../../ApiUrl/ApiUrl';
import { toast } from 'react-toastify';



const EditCompanies = ({setShowEdit, showEdit, companyId,getData, handleCloseMenu}) => {




  /////// Use companyId
  console.log("Madiiii >> ", companyId)

  const handleClose = () => setShowEdit(false);


  const user= localStorage.getItem('token')
const[name,setName]=useState([])
const[bank_id,setBankId]=useState([])

const handleSubmit=()=>{
  Base_Url.put(`companies/${companyId}`, {name, bank_id},{headers:{
    Authorization: `Bearer ${user}`,   
       }, params:{
        name:name,
        bank_id:bank_id
       }}).then((res)=>{
    if(res.data){
      toast.success('Company Added Successfully')
      getData()
      setShowEdit(false)
      handleCloseMenu()
    }

  }).catch((error)=>{
    toast.error('Please Enter Unique company Name');
  })

}
  

  const getDataBank=async()=>{

    const res=await Base_Url.get('banks', {headers:{
       Authorization: `Bearer ${user}`,
     }})
     setData(res.data)
     
     
   }
   
   
   useEffect(() => {
    getDataBank()
    getDataCompany()
   },[])


   const getDataCompany=async()=>{

    const res= await Base_Url.get(`companies/${companyId}`, {headers:{
       Authorization: `Bearer ${user}`,
     }})
     setDataCompany(res.data)
     setName(res.data.name)
     setBankId(res.data.bank_id)

   }
   
   
   const [dataCompany, setDataCompany] = useState([])


   const [data, setData] = useState([])



  return (

    <div>
            <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={showEdit}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'12px'}}>
  <Typography variant='h5' sx={{fontSize:'20px', fontWeight:'700', lineHeight:'27,24px', color:'#011E36'}}>edit Company</Typography>
  <Button onClick={handleClose} sx={{width:'24px', height:'24px'}}><img src={img} alt=''/></Button>

</Box>



<Box sx={{marginTop:'40px'}}>
  <Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}>Company Name</Typography>
  <Box sx={{width:'400px',height:'72px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
      <TextField onChange={(e)=>setName(e.target.value)}  value={name} sx={{marginTop:"10px"}} fullWidth label="Enter the company name" id="name" name='name'   />


</Box> 
</Box>



<Box sx={{marginTop:'20px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700'}}> Bank Account</Typography>
<FormControl sx={{width:'400px', height:'48px', borderRadius:'8px', marginTop:'12px'}}>
      <InputLabel id="select-label">Bank Riyad</InputLabel>
      <Select
        labelId="select-label"
        id="bank_id"
        value={bank_id}
        onChange={(e)=>setBankId(e.target.value)}
        label="Bank Riyad"
        name='bank_id'
      >
{data?.map((item)=>(
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>

))}
      </Select>
    </FormControl>

</Box>


<Box sx={{marginLeft:'110px', marginTop:'40px', display:'flex'}}>
<Button variant="contained" onClick={handleClose}  sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(117, 119, 120, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', textTransform:"capitalize"}}>Cancel</Button>
<Button variant="contained" type='submit' onClick={()=>handleSubmit()} sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(1, 30, 54, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', marginLeft:'20px', textTransform:"capitalize"}}>Edit Company</Button>

</Box>


   





        </Box>
      </StyledModal>
    </div>
  )
}

export default EditCompanies

















const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "589px",
  height:"499px",
  borderRadius: '8px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});