import { Box, Checkbox, FormControl, Grid, InputLabel, MenuItem, Pagination, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Button from '@mui/material/Button';
import SearchIcon from "@mui/icons-material/Search";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Logs from '../../assets/icons/LogsDashboard'
import ShareEmail from './ShareEmail'
import img1 from '../../assets/icons/Vector (21).png'
import img2 from '../../assets/icons/Vector (22).png'
import Base_Url from '../../ApiUrl/ApiUrl';
import img from '../../assets/icons/Vector (16).png'
import img22 from '../../assets/icons/Vector (17).png'
import img3 from '../../assets/icons/Vector (18).png'
import SendIcon from '@mui/icons-material/Send';
import EditDashboard from '../Dashboard/EditDashboard';
import DashboardAddTable from '../Dashboard/DashboardAddTable';
import DeleteDashboard from '../Dashboard/DeleteDashboard';
import { toast } from 'react-toastify';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import { FaSearch } from 'react-icons/fa';




const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgba(243, 243, 243, 1)    ',
    color: 'rgba(124, 124, 124, 1)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '16px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));









const TodayTask = () => {

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 32,
      position: 'relative',
      backgroundColor: '#DCDCDC',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
      fontSize: 16,
      width: '350px',
      padding: '5px 6px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));






  const [data, setData] = useState([])
  const [show, setShow] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [company_id, setCompany] = useState(null)
  const [project_id, setProject] = useState(null)
  const [priority_level, setPriority] = useState(null)
  const [name, setName] = useState('')
  const [id, setId] = useState(null)





  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedIds = [];

    if (selectedIndex === -1) {
      newSelectedIds = [...selectedIds, id];
    } else {
      newSelectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    }

    setSelectedIds(newSelectedIds);
  };













  const handleNameChange = (e) => {
    setName(e.target.value)
  }


  const handleEditShow = (id) => {
    setId(id)
    setShowEdit(true)
  }



  const handleDeleteShow = (id) => {
    setId(id)
    setShowDelete(true)
  }


  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "text.headingColor",
    fontSize: "12px",

    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),

      paddingLeft: `15px`,
      transition: theme.transitions.create("width"),
      maxWidth: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));








  const handleDragStart = (e, id) => {
    e.dataTransfer.setData('text/plain', id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetId) => {
    const draggedId = e.dataTransfer.getData('text/plain');
    const draggedItem = data.find(item => item.id === Number(draggedId));
    const updatedData = data.filter(item => item.id !== Number(draggedId));
    const targetIndex = data.findIndex(item => item.id === Number(targetId));

    updatedData.splice(targetIndex, 0, draggedItem);
    setData(updatedData);
  };

  const user = localStorage.getItem('token')




  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;



  const [current_page, setCurrent_page] = useState(1);
  const [countPages, setCountPages] = useState(0);
  
  const handleChangePagination = (event, value) => {
    setCurrent_page(value)
    getData(value)
  };


  const getDataOneOnly = async () => {

    const res = await Base_Url.get('today_tasks', {
      headers: {
        Authorization: `Bearer ${user}`,

      }, params: {
        date: formattedDate,
        page: data
      }
    })
    if (res.data.items.data) {
      console.log(res.data.items.data);
      // setCurrent_page(res.data.items.data.current_page)
      setCountPages(res.data.items.data.last_page)
      setData(res.data.items.data)
    } else {
      setData([])
    }
  }

  const getData = async (data) => {

    const res = await Base_Url.get('today_tasks', {
      headers: {
        Authorization: `Bearer ${user}`,

      }, params: {
        date: formattedDate,
        page: data
      }
    })
    if (res.data.items.data) {
      console.log(res.data.items.data);
      setData(res.data.items.data)
    } else {
      setData([])
    }
  }

  useEffect(() => {
    getDataOneOnly()
    getDataOptions()
  }, [])


  const [dataFilterCompanies, setDataFilterCompanies] = useState([])
  const [dataFilterprojects, setDataFilterprojects] = useState([])
  const [dataFilterBanks, setDataFilterBanks] = useState([])
  const getDataOptions = async () => {

    const res = await Base_Url.get('data', {
      headers: {
        Authorization: `Bearer ${user}`,
      }
    })
    setDataFilterCompanies(res.data.companies)
    setDataFilterprojects(res.data.projects)
    setDataFilterBanks(res.data.banks)
  }


  const handleAprroved = (id) => {
    Base_Url.post(`item/approved/${id}`, null, {
      headers: {
        Authorization: `Bearer ${user}`
      }
    }
    ).then((res) => {
      toast.success(`${res.data.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getDataOneOnly()
    })
  }



  const getDataFilter = async () => {
    await Base_Url.get('item/filter', {
      headers: {
        Authorization: `Bearer ${user}`
      }, params: {
        company_id: company_id,
        project_id: project_id,
        priority: priority_level,
        date_from: date_from,
        date_to: date_to
      }
    }).then((res) => {
      if (res.data.items.length > 0) {
        setData(res.data.items)
      } else {

        toast.error('No Result Found')
      }

    }).catch((error) => {
      toast.error('No Result Found')
    })
  }


  const getDataSearch = async () => {
    await Base_Url.get('search_items', {
      headers: {
        Authorization: `Bearer ${user}`
      }, params: {
        name: name,
      }
    }).then((res) => {
      // console.log(res.data.items);
      if (res.data.items.length > 0) {
        setData(res.data.items)
        toast.success('Search Successfully')
        setName('')
      } else {
        toast.error('No Result Found')
      }

    }).catch((error) => {
      toast.error('No Result Found')
    })
  }







  const handleClear = () => {
    toast.success('Clear Successfully')
    setPriority(null)
    setProject(null)
    setCompany(null)
    setData([])
    getDataOneOnly()
  }



  const [date_to, setDateTo] = useState(null)
  const [date_from, setDateFrom] = useState(null)


  const handleDateFromChange = (date) => {

    let dateFrom = new Date(date);

    // Get the year, month, and day from the Date object
    const year = dateFrom.getFullYear();
    const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with '0'
    const day = String(dateFrom.getDate()).padStart(2, '0');

    // Create the "yyyy-mm-dd" formatted string
    const formattedDate = `${year}-${month}-${day}`;
    setDateFrom(formattedDate)
  }


  const handleDateToChange = (date) => {

    let dateFrom = new Date(date);

    const year = dateFrom.getFullYear();
    const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with '0'
    const day = String(dateFrom.getDate()).padStart(2, '0');

    // Create the "yyyy-mm-dd" formatted string
    const formattedDate = `${year}-${month}-${day}`;
    setDateTo(formattedDate)
  }


  // handle access 
  const userRole = JSON.parse(localStorage.getItem("CashFlow"));

  return (
    <Box>

      <Box sx={{ marginTop: "20px" }}>
        <Typography variant='h6' sx={{ fontSize: "32px", fontWeight: "700", lineHeight: "43.58px", color: "#011E36" }}>Today's Tasks</Typography>
      </Box>

      <Box sx={{ marginTop: "50px", marginLeft: "0px", width: { xs: "900px", lg: '920px', xl: "1100px", md: "600px" } }}>
        <Grid container spacing={12}>
          <Grid item xs={6} md={8} lg={8} xl={8} >
            <Box sx={{ position: "relative" }}>
              <div className="input-wrapper" style={{ width: '330px' }}>
                <FaSearch id="search-icon" />
                <input
                  placeholder="Type to search..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>




              <Button type='submit' onClick={() => getDataSearch()} sx={{ textTransform: 'capitalize', position: 'absolute', top: '2px', left: { xl: '260px', lg: '260px', md: '280px', xs: '280px' } }}>search</Button>
            </Box>

          </Grid>
          {userRole==='super_admin' || userRole==='admin' ?         
            <Grid item xs={6} md={4} lg={4} xl={4}>
            <Button onClick={() => setShow(true)} variant="contained" sx={{ backgroundColor: "#011E36", width: "198px", height: "48px", padding: "16px, 32px, 16px, 32px", borderRadius: "8px", color: '#DAE2FF', boxShadow: "1", marginLeft: "40px" }} startIcon={<ControlPointRoundedIcon />}>
              <Typography variant='h6' sx={{ color: "#DAE2FF", fontSize: "16px", fontWeight: "600", lineHeight: "21.79px", textTransform: "capitalize" }}>Add New Item</Typography>

            </Button>
          </Grid>  : null}


        </Grid>

      </Box>




      <Box sx={{ marginTop: "50px" }}>


        <Box sx={{ width: { xs: "350px", lg: '910px', xl: "950px", md: "600px" }, height: { xs: "450px", lg: '85px', xl: "85px", md: "190px" }, backgroundColor: "#F2F8FF", borderRadius: "8px", display: 'flex' }}>
          <Grid container spacing={2}>

            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>


              <Box sx={{ marginTop: '22px', marginLeft: '10px' }}>

                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  <DatePicker className="datePickerEditStyle"
                    sx={{ height: "40px", width: '110px', paddingBottom: '40px' }} label='Date From' onChange={handleDateFromChange} value={date_from} renderInput={(params) => <TextField {...params} variant="outlined"
                    />}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>




            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
              <Box sx={{ marginTop: '22px', marginLeft: '15px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker className="datePickerEditStyle" label='Date To' sx={{ height: "40px", paddingTop: '0', width: '110px' }} onChange={handleDateToChange} value={date_to} renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />

                </LocalizationProvider>
              </Box>
            </Grid>


            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>


              <Box>
                <FormControl sx={{ m: 2, width: "95px", marginTop: '22px', borderRadius: '8px', height: '20px', border: '1px solid rgba(242, 248, 255, 1)' }} size="small">
                  <InputLabel id="demo-select-small-label" sx={{ fontSize: '12px', marginTop: '3px', color: 'rgba(69, 94, 116, 1)' }}>Company</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="company_id"
                    name='company_id'
                    value={company_id}
                    label="Company"
                    onChange={(e) => setCompany(e.target.value)}
                  >

                    {dataFilterCompanies?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>

                    ))}
                  </Select>
                </FormControl>

              </Box>
            </Grid>


            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
              <Box>
                <FormControl sx={{ m: 1, width: "95px", marginTop: '22px', borderRadius: '8px', height: '20px', border: '1px solid rgba(242, 248, 255, 1)' }} size="small">
                  <InputLabel id="demo-select-small-label" sx={{ fontSize: '12px', marginTop: '3px', color: 'rgba(69, 94, 116, 1)' }}>Project</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="project_id"
                    name='project_id'
                    value={project_id}
                    label="Projects"
                    onChange={(e) => setProject(e.target.value)}
                  >
                    {dataFilterprojects?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>

                    ))}

                  </Select>
                </FormControl>

              </Box>
            </Grid>


            <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
              <Box>
                <FormControl sx={{ m: 1, width: "95px", marginTop: { xl: '22px', lg: '22px', md: '22px', xs: '22px' }, borderRadius: '8px', height: '20px', border: '1px solid rgba(242, 248, 255, 1)', marginBottom: { xl: '0px', lg: '140px', md: '0px', xs: '0px' } }} size="small">
                  <InputLabel id="demo-select-small-label" sx={{ fontSize: '12px', marginTop: '3px', color: 'rgba(69, 94, 116, 1)' }}>Priority</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="priority_level"
                    value={priority_level || ''}       
                    label="priority_level"
                    name='priority_level'
                    onChange={(e) => setPriority(e.target.value)}
                  >
                    {data.map((item) => (
                      <MenuItem key={item.id} value={item.priority_level}>{item.priority_level}</MenuItem>

                    ))}      </Select>
                </FormControl>

              </Box>
            </Grid>


            <Box sx={{ marginLeft: '30px', marginTop: { xl: '40px', lg: '40px', md: '40px', xs: '40px' }, display: 'flex' }}>

              <Grid item xs={6} md={3} xl={2.5} lg={2.5}>


                <Button variant='contained' onClick={() => handleClear()} type='submit' sx={{ textTransform: 'capitalize', fontSize: '12px', fontWeight: '600', lineHeight: '16.34px', color: 'rgba(69, 94, 116, 1)', backgroundColor: 'rgba(218, 226, 255, 1)', width: '104px', height: '32px', borderRadius: '8px', padding: '8px, 16px, 8px, 16px' }}>Clear Filters</Button>
              </Grid>


              <Grid item xs={6} md={3} xl={2}>

                <Button variant='contained' onClick={() => getDataFilter()} sx={{ textTransform: 'capitalize', fontSize: '12px', fontWeight: '600', lineHeight: '16.34px', color: 'rgba(218, 226, 255, 1)', backgroundColor: 'rgba(69, 94, 116, 1)', width: '104px', height: '32px', borderRadius: '8px', padding: '8px, 16px, 8px, 16px', marginLeft: '70px' }}>Apply Filters</Button>
              </Grid>

            </Box>
          </Grid>
        </Box>


      </Box>















      {userRole==='super_admin' || userRole==='admin'  ?         <Box sx={{ marginY: '30px', marginLeft: { xs: '0px', md: '290px', lg: '630px', xl: '650px' } }}>
        <Button onClick={() => setShowEmail(true)} variant="contained" sx={{ backgroundColor: "#011E36", width: "198px", height: "48px", padding: "16px, 32px, 16px, 32px", borderRadius: "8px", color: '#DAE2FF', boxShadow: "1", marginLeft: "80px" }} startIcon={<SendIcon />}>
          <Typography variant='h6' sx={{ color: "#DAE2FF", fontSize: "16px", fontWeight: "600", lineHeight: "21.79px", textTransform: "capitalize" }}>Share to Email</Typography>

        </Button>

      </Box>
   : null}







      <TableContainer sx={{ marginTop: '30px' }}>
        <Table sx={{ maxWidth: { xs: "300px", lg: '920px', xl: "1100px", md: "600px" }}} aria-label="customized table" >
          <TableHead>
            <TableRow sx={{ color: 'rgba(124, 124, 124, 1)' }}>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Item Name Value</StyledTableCell>
              <StyledTableCell>Item Value</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Priority</StyledTableCell>
              {/* <StyledTableCell>Total&nbsp;(g)</StyledTableCell> */}
              <StyledTableCell>Actions</StyledTableCell>
              <StyledTableCell>Logs</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {data.map((item) => (
              <StyledTableRow
                sx={{ backgroundColor: `${item.status === 'paid' ? 'rgba(33, 150, 243, 1)' : ''}` }}
                draggable
                onDragStart={(e) => handleDragStart(e, item.id)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, item.id)}
                key={item.id}>
                <StyledTableCell component="th" scope="row" >
                  <Checkbox
                    checked={selectedIds.indexOf(item.id) !== -1}
                    onChange={(event) => handleCheckboxChange(event, item.id)}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  {item.name}
                </StyledTableCell >
                <StyledTableCell>

                {item.value >= 0 ? (
                        <img src={img1} alt="" srcset="" />
                      ) : (
                        <img src={img2} alt="" />
                      )}{" "}
                      {item.value.toLocaleString()}   
                      </StyledTableCell>
   
                <StyledTableCell>{item.date}</StyledTableCell>
                <StyledTableCell >{item.priority_level}</StyledTableCell>
                {/* <StyledTableCell >{item.max_period}</StyledTableCell> */}
                <StyledTableCell >    <Box sx={{ display: 'flex', position: 'relative', padding: '16px, 8px, 16px, 8px' }} >
                  <Box sx={{ backgroundColor: 'rgba(33, 150, 243, 1)', borderRadius: '50%', width: '20px', height: '20px', cursor: 'pointer' }}><img onClick={() => handleAprroved(item.id)} style={{ position: 'absolute', top: '6px', left: '4px' }} src={img} alt='' /></Box>
                  <Box sx={{ backgroundColor: 'rgba(174, 0, 167, 1)', borderRadius: '50%', width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }}><img onClick={() => handleEditShow(item.id)} style={{ position: 'absolute', top: '4px', left: '34px' }} src={img22} alt='' /></Box>

                  {/* <Box>
        <Box sx={{backgroundColor:'rgba(174, 0, 167, 1)', borderRadius:'50%', width:'20px', height:'20px', marginLeft:'10px'}}>
            <Box><img style={{position:'absolute', top:'6px', left:'65px'}}  src={img4} alt=''/></Box>
            <Box sx={{display:'flex',position:'absolute', top:'9px', left:'67px' }}>
<Box sx={{ backgroundColor:'rgba(174, 0, 167, 1)', width:'1.5px', height:'1.5px', borderRadius:'50px'}}></Box>
<Box sx={{ backgroundColor:'rgba(174, 0, 167, 1)', width:'1.5px', height:'1.5px', marginLeft:'1px', borderRadius:'50px'}}></Box>
<Box sx={{ backgroundColor:'rgba(174, 0, 167, 1)', width:'1.5px', height:'1.5px' , borderRadius:'50px', marginLeft:'0.6px'}}></Box>
            </Box>
        </Box>

        </Box>
       */}

                  <Box sx={{ backgroundColor: 'rgba(243, 66, 53, 1)', borderRadius: '50%', width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }}><img onClick={() => handleDeleteShow(item.id)} style={{ position: 'absolute', top: '3px', left: '65px' }} src={img3} alt='' /></Box>
                </Box>
                </StyledTableCell>
                <StyledTableCell ><Logs /></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>



      <Box sx={{ marginY: '30px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}><Pagination count={countPages} shape='circular' page={current_page} onChange={handleChangePagination} color="primary" /></Box>

      {showEdit && <EditDashboard show={showEdit} setShow={setShowEdit} id={id} companies={setDataFilterCompanies} projects={setDataFilterprojects} banks={setDataFilterBanks}  getDataEdit={getData} />}
      {show && <DashboardAddTable show={show} setShow={setShow}  getData={getData} />}
      {showDelete && <DeleteDashboard show={showDelete} setShow={setShowDelete} id={id} getDataEdit={getData}/>}
      {showEmail && <ShareEmail show={showEmail} setShow={setShowEmail} selectedIds={selectedIds} />}
    </Box>
  )
}

export default TodayTask
