import React from 'react'
import icon from '../images/Vector (3).png'

const Bank = () => {
  return (
    <div>
      <img src={icon} alt='Bank Acconts'/>
    </div>
  )
}

export default Bank
