import { Box, Button, Fade, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "../../assets/icons/Vector (7).png";
import img1 from "../../assets/icons/dots.png";
import img3 from "../../assets/icons/Vector (9).png";
import img4 from "../../assets/icons/Vector (10).png";
import img5 from "../../assets/icons/Vector (23).png";
import img7 from "../../assets/icons/Vector (13).png";
import { Link, useParams } from "react-router-dom";
import EditCompanies from "./EditProject";
import AddCompanies from "./AddProject";
import DeleteCompanies from "./DeleteProject";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Base_Url from "../../ApiUrl/ApiUrl";

const Projects = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [data, setData] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setcompanyId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (id) => {
    setShowEdit(true);
  };

  const handleDelete = (id) => {
    setShowDelete(true);
  };

  const [companyId, setcompanyId] = useState(null);

  const { id } = useParams();

  const user = localStorage.getItem("token");

  const getData = async () => {
    const res = await Base_Url.get(`companies/${id}`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    setName(res.data.name);
    setData(res.data.projects);
    console.log(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const [name, setName] = useState([]);

  // handle access
  const userRole = JSON.parse(localStorage.getItem("CashFlow"));

  return (
    <Box>
      <Box sx={{ marginTop: "20px", display: "flex" }}>
        <Button sx={{ marginTop: "6px" }}>
          {" "}
          <Link to="/companies">
            <img src={img} alt="" srcset="" />
          </Link>
        </Button>
        <Typography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "43.58px",
            color: "#011E36",
          }}
        >
          {name}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {data?.map((item) => (
          <Grid item lg={4} xl={3} xs={12} md={6} key={item.id}>
            <Box sx={{ marginTop: "60px" }}>
              <Box
                sx={{
                  width: "256px",
                  height: "148px",
                  boxShadow: "1",
                  borderRadius: "8px",
                  padding: "32px, 48px, 32px, 48px",
                }}
              >
                {/* <Button sx={{position:"absolute", top:"9px", right:'0'}} onClick={handleToggle}><img src={img1} alt="" srcset="" /></Button> */}

                {userRole === "super_admin" || userRole === "admin" ? (
                  <Box
                    sx={{
                      alignItems: "end",
                      justifyContent: "end",
                      display: "flex",
                      marginRight: "5px",
                      paddingTop: "20px",
                    }}
                  >
                    <div>
                      <Button
                        id="fade-button"
                        aria-controls={open ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => handleClick(e, item.id)}
                      >
                        <img src={img1} alt="" srcset="" />
                      </Button>

                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                      >
                        <MenuItem onClick={() => handleEdit(item.id)}>
                          <Box sx={{ display: "flex" }}>
                            <Box sx={{ position: "relative" }}>
                              <Box
                                sx={{
                                  width: "15.82px",
                                  height: "15.82px",
                                  borderRadius: "50px",
                                  backgroundColor: "rgba(181, 199, 70, 1)",
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "-4px",
                                  left: "2px",
                                }}
                              >
                                <img src={img3} alt="" srcset="" />
                              </Box>
                            </Box>

                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "19.07px",
                                color: "#455E74",
                                marginLeft: "8px",
                              }}
                            >
                              Edit Project
                            </Typography>
                          </Box>
                        </MenuItem>
                        <MenuItem onClick={() => handleDelete(item.id)}>
                          <Box sx={{ display: "flex" }}>
                            <Box sx={{ position: "relative" }}>
                              <Box
                                sx={{
                                  width: "15.82px",
                                  height: "15.82px",
                                  borderRadius: "50px",
                                  backgroundColor: "rgba(243, 66, 53, 1)",
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "-4px",
                                  left: "3px",
                                  width: "9.17px",
                                  height: "9.17px",
                                }}
                              >
                                <img
                                  style={{ width: "9.17px", height: "9.17px" }}
                                  src={img4}
                                  alt=""
                                  srcset=""
                                />
                              </Box>
                            </Box>

                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "19.07px",
                                color: "#455E74",
                                marginLeft: "8px",
                              }}
                            >
                              Delete Project
                            </Typography>
                          </Box>
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                ) : null}

                <Box sx={{ marginLeft: "90px", marginTop: "0px" }}>
                  <Box
                    sx={{
                      width: "48px",
                      height: "48px",
                      marginLeft: "10px",
                      marginBottom: "0px",
                    }}
                  >
                    <img src={img5} alt="" srcset="" />
                  </Box>

                  <Box
                    sx={{ width: "900px", height: "90px", marginLeft: "-20px" }}
                  >
                    <Typography component="p">
                      <Link
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          lineHeight: "38.13px",
                          color: "#011E36",
                          textDecoration: "none",
                          paddingLeft: "10px",
                          marginTop: "13px",
                        }}
                        to={`/projectDetails/${item.id}`}
                      >
                        {item.name}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}

          <Box sx={{ marginTop: "70px" }}> 
                 {userRole === "super_admin" || userRole === "admin" ? (

            <Button onClick={() => setShowAdd(true)}>
              {" "}
              <Box
                sx={{
                  width: "256px",
                  height: "148px",
                  boxShadow: "1",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "48px",
                    height: "48px",
                    backgroundColor: "#DAE2FF",
                    borderRadius: "50px",
                    marginLeft: "100px",
                    position: "absolute",
                    top: "40px",
                  }}
                >
                  <img
                    src={img7}
                    style={{ marginTop: "12px", marginLeft: "1px" }}
                    alt=""
                    srcset=""
                  />
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#455E74",
                    lineHeight: "19,07px",
                    textTransform: "capitalize",
                    position: "absolute",
                    top: "110px",
                    left: "70px",
                  }}
                >
                  Add New Project
                </Typography>
              </Box>
            </Button> 
                   ) : null}

          </Box>
      </Grid>

      {showEdit && (
        <EditCompanies
          setShowEdit={setShowEdit}
          showEdit={showEdit}
          companyId={companyId}
          getData={getData}
          handleCloseMenu={handleClose}
        />
      )}
      {showAdd && (
        <AddCompanies
          setShowAdd={setShowAdd}
          showAdd={showAdd}
          getData={getData}
          handleCloseMenu={handleClose}
        />
      )}
      {showDelete && (
        <DeleteCompanies
          setShowDelete={setShowDelete}
          showDelete={showDelete}
          companyId={companyId}
          getData={getData}
          handleCloseMenu={handleClose}
        />
      )}
    </Box>
  );
};

export default Projects;
