import React from 'react'
import icon from './Vector (28).png'

const Companies = () => {
  return (
    <div>
      <img src={icon} alt='Compaines' color='#DAE2FF'/>
    </div>
  )
}

export default Companies
