import axios from "axios"




const ApiUrl='https://cashflowbackend-dev.joe13th.com/api/'


const Base_Url= axios.create({
    baseURL:ApiUrl
})


export default Base_Url