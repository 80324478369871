import { Box, Grid,  Typography } from '@mui/material'
import React, { useState } from 'react'
import img from '../../assets/icons/Illustration.png'
import {  useFormik } from 'formik'
import * as Yup from 'yup'
import Base_Url from '../../ApiUrl/ApiUrl'
import {  Link, useNavigate } from 'react-router-dom'
import swal from 'sweetalert';
import { toast } from 'react-toastify'
import Button from '@mui/joy/Button';
import SvgIcon from '@mui/joy/SvgIcon';
import { styled } from '@mui/joy';




const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;


const Register = () => {

    const [imageData, setImageData] = useState([])
    const [userImage, setUserImage] = useState([])
  

    const imgProfile = (event) => {
        let image = event.target;
        console.log("EEEEEEE > ", image)
        if (image.files && image.files[0]) {
          var reader = new FileReader();
          reader.onload = (e) => {
            // console.log("EEEEEEE > ", e.target.result)
    
            setImageData(e.target.result);
          }
          reader.readAsDataURL(image.files[0]);
          setUserImage(image.files[0]);
        }
      }

      console.log(userImage);
    


let navigate=useNavigate()

    const formik = useFormik({
        initialValues:{
            name:"",
           email:"",
          password:"",
          organization_name:"",
      
        },
    
        validationSchema: Yup.object({
          email: Yup.string().email('Invalid email format').required("Email is required"),
          password: Yup.string().required("Password is required"),
        }),
    
        onSubmit:(values)=>{
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("password", values.password);
            formData.append("organization_name", values.organization_name);
            formData.append("organization_logo", userImage);
      
          Base_Url.post("register", formData).then((res)=>{     
            
              swal("Register Successfully!", "", "success");
              navigate('/')          
            
         }).catch((error)=>{
        //  console.log(error.response.data.message);
        toast.error('Failed Email or Password')
        toast.error(`${error.response.data.message}`)

        console.log(error);


         })
                 }
      })










    
    


  return (
<Box sx={{justifyContent:'center', display:'flex', alignItems:'center', marginTop:'100px', marginX:'110px' }}>




<Grid container spacing={2}>
    <Grid item sx={12} md={12} lg={6} xl={6}>
                <Box sx={{width:'519px', height:'591px'}}>
                    <img src={img} alt="login"  />
                </Box>
    </Grid>



    <Grid item sx={12} md={12} lg={6} xl={6}>
        <Box sx={{ marginTop:'110px', textAlign:'center'}}>
            <Typography sx={{fontSize:'24px', fontWeight:'800', lineHeight:'32.68px', color:'rgba(1, 30, 54, 1)', textAlign:'center'}}>
Register
            </Typography>


<form onSubmit={formik.handleSubmit}>



<input type="text" id='name' name='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} style={{marginTop:"60px", width:'416px',height:'44px', borderRadius:'16px', border:'1px solid rgba(205, 205, 205, 1)', padding:'0px 16px 0px 16px' , fontSize:'14px'}} placeholder=' User Name' />
<Typography component='p'  sx={{fontSize:"14px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px' }}>    {formik.touched.name &&formik.errors.name}</Typography>


<input type="email" id='email' name='email' onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} style={{marginTop:"20px", width:'416px',height:'44px', borderRadius:'16px', border:'1px solid rgba(205, 205, 205, 1)', padding:'0px 16px 0px 16px', fontSize:'14px'}} placeholder='Email' />
<Typography component='p'  sx={{fontSize:"14px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px' }}>    {formik.touched.email &&formik.errors.email}</Typography>
<input type="password" id='password' name='password' onChange={formik.handleChange} value={formik.values.password} onBlur={formik.handleBlur} style={{marginTop:"20px", width:'416px',height:'44px', borderRadius:'16px', border:'1px solid rgba(205, 205, 205, 1)', padding:'0px 16px 0px 16px', fontSize:'14px'}} placeholder='Password' />
<Typography component='p'  sx={{fontSize:"14px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px' }}>    {formik.touched.password &&formik.errors.password}</Typography>

<input type="text" id='organization_name' name='organization_name' onChange={formik.handleChange} value={formik.values.organization_name} onBlur={formik.handleBlur} style={{marginTop:"20px", width:'416px',height:'44px', borderRadius:'16px', border:'1px solid rgba(205, 205, 205, 1)', padding:'0px 16px 0px 16px' , fontSize:'14px'}} placeholder=' Organization Name' />
<Typography component='p'  sx={{fontSize:"14px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px' }}>    {formik.touched.organization_name &&formik.errors.organization_name}</Typography>

<Box sx={{marginRight:'180px', marginTop:'30px'}}>

<Button
      component="label"
      role={undefined}
      tabIndex={-1}
      variant="outlined"
      color="neutral"
      startDecorator={
        <SvgIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
          </svg>
        </SvgIcon>
      }
    >
      Upload Organization Logo
      <VisuallyHiddenInput type="file"    onChange={(e) => imgProfile(e)}
/>
    </Button>
</Box>


<Box sx={{marginTop:'35px'}}>
  <Typography>
  Already have an account ? <Link style={{textDecoration:'none', fontSize:'16px', fontWeight:'bold'}} to='/'>Login</Link>
  </Typography>
</Box>
            <button type='submit' style={{marginTop:'40px', marginBottom:'50px',width:'400px', height:'51px', borderRadius:'16px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(1, 30, 54, 1)', color:'rgba(218, 226, 255, 1)', boxShadow:'1', marginLeft:'20px', textTransform:"capitalize", fontSize:'16px', fontWeight:'700'}}>Register</button>

</form>


        </Box>
    </Grid>
</Grid>

</Box>
  )
}

export default Register