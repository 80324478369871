import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import { Box, Grid, Stack } from "@mui/material";
import Navbar from "../components/Navbar/Navbar";

const RootLayout = () => {






  return (
    <Stack direction="row" spacing={2}>


      <Grid container spacing={2}>

<Grid item xs={4.5} md={4}  xl={2.5} lg={3}>
<Box  sx={{   width: "20%" }}>
  <Sidebar />
</Box>
</Grid>


     <Grid item xs={7.5} xl={9.5} lg={9} md={8}>
      <Box sx={{ height: "100vh", overflowX: "auto", minWidth: "80%" }} >
        <Navbar/>
        <Outlet />
      </Box>
      </Grid> 
      
      </Grid>





    </Stack>
  );
};

export default RootLayout;
