import styled from '@emotion/styled';
import { Backdrop, Box, Button, Modal, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import img from "../../assets/icons/Vector (15).png"
import img1 from "../../assets/icons/Vector (21).png"
import Base_Url from '../../ApiUrl/ApiUrl';
import img2 from '../../assets/icons/Vector (22).png'
import img3 from '../../assets/icons/Vector (14).png'




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor:'rgba(243, 243, 243, 1)    ',
      color: 'rgba(124, 124, 124, 1)',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '16px',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));






const LogsPop = ({show, setShow, id}) => {



    const handleClose = () => setShow(false);
    const [data,setData]=useState([])
    const handleDragStart = (e, id) => {
        e.dataTransfer.setData('text/plain', id);
      };
      const handleDragOver = (e) => {
        e.preventDefault();
      };
      const handleDrop = (e, targetId) => {
        const draggedId = e.dataTransfer.getData('text/plain');
        const draggedItem = data.find(item => item.id === Number(draggedId));
        const updatedData = data.filter(item => item.id !== Number(draggedId));
        const targetIndex = data.findIndex(item => item.id === Number(targetId));
    
        updatedData.splice(targetIndex, 0, draggedItem);
        setData(updatedData);
      };

      const user= localStorage.getItem('token')

    
    




    const getData=async()=>{

        const res=await Base_Url.get(`get_log_by_id/${id}`, {headers:{
           Authorization: `Bearer ${user}`,
           
         }})
         setData(res.data.logs)
        
       }
    
      useEffect(() => {
       getData() 
       },[])


  return (
   <Box>
        <StyledModal
    aria-labelledby="unstyled-modal-title"
    aria-describedby="unstyled-modal-description"
    open={setShow}
    onClose={handleClose}
    slots={{ backdrop: StyledBackdrop }}
    >
  
  <Box sx={{overflowY:'auto', whiteSpace:'nowrap'}}>
  <Box sx={style}>
    <Box sx={{display:'flex',marginTop:'30px' , justifyContent:'space-between', alignItems:'center'}}>
    <Box sx={{display:'flex'}}>
    <Button sx={{width:'24px', height:'24px'}}><img src={img} alt=''/></Button>
    
    <Typography variant='h5' sx={{fontSize:'20px', fontWeight:'700', lineHeight:'27,24px', color:'#011E36'}}> Logs </Typography>
    
    </Box>
    <Button onClick={handleClose} sx={{width:'24px', height:'24px'}}><img src={img3} alt=''/></Button>
    
    </Box>
    
    


    <TableContainer  sx={{marginTop:'50px'}}>
      <Table sx={{ maxWidth: "1100px" }} aria-label="customized table" >
        <TableHead>
          <TableRow sx={{color:'rgba(124, 124, 124, 1)'}}>
            <StyledTableCell>Item Name </StyledTableCell>
            <StyledTableCell>Item Value</StyledTableCell>
            <StyledTableCell>Date&nbsp;(g)</StyledTableCell>
            <StyledTableCell>Priority&nbsp;(g)</StyledTableCell>
            <StyledTableCell>Total&nbsp;(g)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <StyledTableRow  
            sx={{ backgroundColor:`${item.status==='paid'? 'rgba(33, 150, 243, 1)':''}`}}          
            draggable
            onDragStart={(e) => handleDragStart(e, item.id)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, item.id)}
  key={item.id}>
              <StyledTableCell  component="th" scope="row">
                {item.name}
              </StyledTableCell>
              <StyledTableCell >{item.value>150? <img src={img1} alt="" srcset="" />  :<img src={img2} alt=''/>}  {item.value}</StyledTableCell>
              <StyledTableCell>{item.date}</StyledTableCell>
              <StyledTableCell >{item.priority_level}</StyledTableCell>
              <StyledTableCell >{item.max_period}</StyledTableCell>
          </StyledTableRow>
          ))}


          
        </TableBody>
      </Table>
    </TableContainer>


    
    
    
    <Box sx={{marginLeft:'800px', marginTop:'40px'}}>
    <Button variant="contained" onClick={handleClose}  sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(117, 119, 120, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', textTransform:"capitalize",}}>Cancel</Button>
    </Box>
    
 
    
    
    
    
    
    
    
    </Box>
  </Box>
    </StyledModal>
   </Box>
  )
}

export default LogsPop





const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "1100px",
  height:"450px",
  borderRadius: '8px',
  // padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  // boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
  overflowY:'auto'
});