import styled from '@emotion/styled';
import {  Backdrop, Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import img from "../../assets/icons/Vector (14).png"
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Base_Url from '../../ApiUrl/ApiUrl';
import { toast } from 'react-toastify';


const AddCompanies = ({setShowAdd, showAdd, getData, handleCloseMenu}) => {
  const handleClose = () => setShowAdd(false);

  const user= localStorage.getItem('token')

  const formik = useFormik({
    initialValues:{
  
      bank_id:'',
      name:'',
      cash_id:''
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, "Company Name must be 4 characters or more.")
        .required("Company Name is required"),

        
    }),



    onSubmit:(values)=>{
      Base_Url.post('companies', values,{headers:{
        Authorization: `Bearer ${user}`,   
           }}).then((res)=>{
        if(res.data){
toast.success('Company Added Successfully')
getData()
setShowAdd(false)
handleCloseMenu()
        }
      }).catch((error)=>{
        toast.error(error.response.data.message);
      })

    }
  })


  
  const getDataBank=async()=>{

    const res=await Base_Url.get('banks', {headers:{
       Authorization: `Bearer ${user}`,
     }})
     setData(res.data)
     
   }

   const getDataCash=async()=>{

    const res=await Base_Url.get('cashes', {headers:{
       Authorization: `Bearer ${user}`,
     }})
     setDataCash(res.data)
     
   }
   
   
   useEffect(() => {
    getDataBank()
    getDataCash()
   },[])
   
   
   
   
     const [data, setData] = useState([])
     const [dataCash, setDataCash] = useState([])
   

  return (



    <div>
            <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={showAdd}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'12px'}}>
  <Typography variant='h5' sx={{fontSize:'20px', fontWeight:'700', lineHeight:'27,24px', color:'#011E36'}}>Add New Company</Typography>
  <Button onClick={handleClose} sx={{width:'24px', height:'24px'}}><img src={img} alt=''/></Button>

</Box>

<form onSubmit={formik.handleSubmit}>


<Box sx={{marginTop:'40px'}}>
  <Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}>Company Name</Typography>
  <Box sx={{width:'400px',height:'72px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
      <TextField onChange={formik.handleChange} onBlur={formik.handleBlur}   value={formik.values.name} sx={{marginTop:"10px"}} fullWidth label="Enter the company name" id="name" name='name'   />
{formik.touched.name &&formik.errors.name ? <Typography component='p'  sx={{fontSize:"14px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px' }}>  {formik.errors.name} </Typography> :''}


</Box> 
</Box>



<Box sx={{marginTop:'30px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700'}}> Bank Account</Typography>
<FormControl sx={{width:'400px', height:'48px', borderRadius:'8px', marginTop:'12px'}}>
      <InputLabel id="select-label">Bank Riyad</InputLabel>
      <Select
        labelId="select-label"
        id="bank_id"
        value={formik.values.bank_id}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label="Bank Riyad"
        name='bank_id'
      >


{data?.map((item)=>(
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>

))}
      </Select>
    </FormControl>
{formik.touched.bank_id &&formik.errors.bank_id ? <Typography component='p'  sx={{fontSize:"14px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px' }}>  {formik.errors.bank_id} </Typography> :''}

</Box>

<Box sx={{marginTop:'30px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700'}}> Cash</Typography>
<FormControl sx={{width:'400px', height:'48px', borderRadius:'8px', marginTop:'12px'}}>
      <InputLabel id="select-label">Cash</InputLabel>
      <Select
        labelId="select-label"
        id="cash_id"
        value={formik.values.cash_id}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label="Cash"
        name='cash_id'
      >


{dataCash?.map((item)=>(
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>

))}
      </Select>
    </FormControl>
{formik.touched.cash_id &&formik.errors.cash_id ? <Typography component='p'  sx={{fontSize:"14px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px' }}>  {formik.errors.cash_id} </Typography> :''}

</Box>


<Box sx={{marginLeft:'110px', marginTop:'40px', display:'flex'}}>
<Button variant="contained" onClick={handleClose}  sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(117, 119, 120, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', textTransform:"capitalize"}}>Cancel</Button>
<Button variant="contained" type='submit' sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(1, 30, 54, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', marginLeft:'20px', textTransform:"capitalize"}}>Add Company</Button>

</Box>


   
</form>





        </Box>
      </StyledModal>
    </div>
  )
}

export default AddCompanies









const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "589px",
  height:"599px",
  borderRadius: '8px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});

