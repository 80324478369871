import SidebarLinks from "./SidebarLinks";
import linksData from "../../utlities/SidebarData";

import { Box, Typography } from "@mui/material";

const Sidebar=()=> {
  return (
    <Box
      component="aside"
      sx={{
       width: {xs:"190px", lg:'240px', xl:"284px", md:"230px"},
        bgcolor: "#F2F8FF",
        height: "100vh",
       
      }}
    >

      
<Box sx={{marginX:"70px", paddingTop:"60px", marginBottom:"70px"}}>


<Box sx={{width:"50px", height:"50px", backgroundColor:"#011E36", borderRadius:"30px", marginLeft:"28px"}}>
  <Typography variant="h5" sx={{fontSize:"14px", color:"#FFFFFF", display:"flex", justifyContent:"center", alignItems:"center", paddingTop:'15px'}}>logo</Typography>
</Box>
        <Typography
          variant="h5"
          component="h1"
          color="#011E36"
          sx={{ fontWeight: "700", fontSize: "16px",lineHeight:"21.79px", marginTop:"13px", paddingLeft:"20px"}}
        >
                System Logo

        </Typography>


</Box>
 
      <Box
        sx={{
          paddingLeft: "16px",
          "@media (max-width: 600px)": {
            paddingLeft: "4px",
          },
        }}
      >

        <Box
          component="nav"
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {linksData.map((link) => (
            <SidebarLinks
              key={link.title}
              title={link.title}
              path={link.path}
              icon={link.icon}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Sidebar;
