import React from 'react'
import Login from '../components/Login/Login'

const LoginPage = ({saveUser}) => {
  return (
    <div>
      <Login saveUser={saveUser}/>
    </div>
  )
}

export default LoginPage
