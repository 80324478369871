// import DashboardPage from "./pages/DashboardPage";

import { RouterProvider, createBrowserRouter, createHashRouter } from "react-router-dom";
import RootLayout from "./RootLayouts/RootLayouts";
import BankAccountsPage from "./pages/BankAccountsPage";
import DashboardPage from "./pages/DashboardPage";
import LogsPage from "./pages/LogsPage";
import CompaniesPage from "./pages/CompaniesPage";
import TodayTaskPage from "./pages/TodayTaskPage";
import Projects from "./components/Projects/Projects";
import ProjectDetails from "./components/ProjectDetails/ProjectDetails";
import LoginPage from "./pages/LoginPage";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";

// Protected Route 
import ProtectetRoute from './components/ProtectetRoute/ProtectetRoute '
import NotFound from "./components/NotFound/NotFound";
import RegisterPage from "./pages/RegisterPage";
import CashPage from "./pages/CashPage";
import UsersPage from "./pages/UsersPage";

function App() {


  const [userData, setuserData] = useState(null)

  let saveUser=()=>{
    let encoded = localStorage.getItem('token')
    let decoded =jwtDecode(encoded)
    setuserData(decoded)
  }

useEffect(() => {

if(localStorage.getItem('token')){
  saveUser()
}

}, [])



  const router = createHashRouter(
    [ {path: "/",element: <RootLayout />,children: [
          {
            path: "bank",
            element: <ProtectetRoute userData={userData}> <BankAccountsPage /> </ProtectetRoute>,
          },
          {
            path: "home",
            element: <ProtectetRoute userData={userData}> <DashboardPage /></ProtectetRoute>,
          },
          {
            path: "logs",
            element:<ProtectetRoute userData={userData}><LogsPage /> </ProtectetRoute> ,
          },
  
          {
            path: "companies",
            element:<ProtectetRoute userData={userData}>  <CompaniesPage /></ProtectetRoute> ,
          },
          {
            path: "task",
            element:<ProtectetRoute userData={userData}>  <TodayTaskPage /> </ProtectetRoute> ,
          },
          {
            path: "project/:id",
            element: <ProtectetRoute userData={userData}> <Projects /> </ProtectetRoute> ,
          },        {
            path: "projectDetails/:id",
            element: <ProtectetRoute userData={userData}> <ProjectDetails /> </ProtectetRoute> ,
          },
          {
            path:"cash",
            element: <ProtectetRoute userData={userData}><CashPage/></ProtectetRoute>
          },
          {
            path:"users",
            element: <ProtectetRoute userData={userData}><UsersPage/></ProtectetRoute>
          }
              
  
  
      
        ],
      },
      {index:true, element:<LoginPage saveUser={saveUser}/>},
      {path:'register', element:<RegisterPage/>},
  {path:'*', element:<NotFound/>}
    ]
    )
  
  

  return (
    <div>
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
