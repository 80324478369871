import styled from '@emotion/styled';
import { Backdrop, Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import img from "../../assets/icons/Vector (14).png"
import Base_Url from '../../ApiUrl/ApiUrl';
import { toast } from 'react-toastify';

const EditBank = ({setShowEdit, showEdit, BankId, getDataBank, handleCloseMenu}) => {

  const user= localStorage.getItem('token')

  const handleClose = () => setShowEdit(false);

  const editBank=()=>{
    
    Base_Url.put(`banks/${BankId}`, {name,account_number, actual_balance },{headers:{
      Authorization: `Bearer ${user}`,   
         }, params:{
          name:name,
          account_number:account_number,
          actual_balance:actual_balance
         }}).then((res)=>{
if(res.data){
  toast.success('Bank Added Successfully',{onClose:'5000'})
  getDataBank()
  setShowEdit(false)
  handleCloseMenu()
}

    }).catch((error)=>{
      toast.error(error.response.data.message);

    })

  }

  


  const [account_number, setDataAccount_number]=useState([])
  const [name, setDataName]=useState([])
  const [actual_balance, setDataActual]=useState([])

  const getData=async()=>{

    const res=await Base_Url.get(`check_balance/${BankId}`, {headers:{
       Authorization: `Bearer ${user}`,
     }})

     console.log(res.data.bank.account_number);

     setDataAccount_number(res.data.bank.account_number)
     setDataName(res.data.bank.name)
     setDataActual(res.data.bank.actual_balance)
    }     

   
   
   useEffect(() => {
   getData()
   },[])






  return (
    <div>
          <StyledModal
aria-labelledby="unstyled-modal-title"
aria-describedby="unstyled-modal-description"
open={showEdit}
onClose={handleClose}
slots={{ backdrop: StyledBackdrop }}
>
<Box sx={style}>
<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'12px'}}>
<Typography variant='h5' sx={{fontSize:'20px', fontWeight:'700', lineHeight:'27,24px', color:'#011E36'}}>Edit Bank Account</Typography>
<Button onClick={handleClose} sx={{width:'24px', height:'24px'}}><img src={img} alt=''/></Button>

</Box>


<Box sx={{marginTop:'40px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}>Bank Name</Typography>
<Box sx={{width:'400px',height:'48px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
<TextField                     
                    onChange={(e)=>setDataName(e.target.value)}    value={name}


sx={{marginTop:"10px"}} fullWidth label="Riyad Bank" id="name" name='name'  />


</Box> 
</Box>




<Box sx={{marginTop:'40px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}> Bank Account Number</Typography>
<Box sx={{width:'400px',height:'48px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
<TextField                     
          id='account_number' name='account_number'
          onChange={(e)=>setDataAccount_number(e.target.value)}    value={account_number}

sx={{marginTop:"10px"}} fullWidth label="Account Number"    />


</Box> 
</Box>



<Box sx={{marginTop:'40px'}}>
<Typography variant='h5' sx={{fontSize:"14px", lineHeight:'19,07px', color:'rgba(69, 94, 116, 1)', fontWeight:'700' }}>  Actual Balance
</Typography>
<Box sx={{width:'400px',height:'48px', padding:'16px, 24px, 16px, 24px', border:'1px solid gba(189, 189, 189, 1) ' , borderRadius:'8px'}} >
<TextField                     
          
        onChange={(e)=>setDataActual(e.target.value)}    value={actual_balance}

sx={{marginTop:"10px"}} fullWidth label="Actual Balance" id="actual_balance" name='actual_balance'  />


</Box> 
</Box>






<Box sx={{marginLeft:'110px', marginTop:'40px', display:'flex'}}>
<Button variant="contained" onClick={handleClose}  sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(117, 119, 120, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', textTransform:"capitalize"}}>Cancel</Button>
<Button variant="contained" type='submit' onClick={()=>editBank()} sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(1, 30, 54, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', marginLeft:'20px', textTransform:"capitalize"}}>Edit Bank Account
  
</Button>

</Box>






</Box>
</StyledModal>
    </div>
  )
}

export default EditBank




const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "589px",
  height:"550px",
  borderRadius: '8px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});