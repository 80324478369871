import React from 'react'
import icon from '../images/Vector (2).png'

const TodayTask = () => {
  return (
    <div>
      <img src={icon} alt='Today Task '/>
    </div>
  )
}

export default TodayTask
