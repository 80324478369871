import React from 'react'
import icon from '../images/icon1.svg'

const IconNumber = () => {
  return (
    <div>
      <img src={icon} alt=''/>
    </div>
  )
}

export default IconNumber
