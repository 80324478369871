import React from 'react'
import TodayTask from '../components/TodayTasks/TodayTask'

const TodayTaskPage = () => {
  return (
    <div>
      <TodayTask/>
    </div>
  )
}

export default TodayTaskPage
