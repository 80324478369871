import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Base_Url from "../../ApiUrl/ApiUrl";
import { toast } from "react-toastify";
import EditDashboard from "../Dashboard/EditDashboard";
import DashboardAddTable from "../Dashboard/DashboardAddTable";
import DeleteDashboard from "../Dashboard/DeleteDashboard";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import img1 from "../../assets/icons/Vector (21).png";
import img2 from "../../assets/icons/Vector (22).png";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaSearch } from "react-icons/fa";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(243, 243, 243, 1)    ",
    color: "rgba(124, 124, 124, 1)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Logs = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [changed_by, setChanged_by] = useState(null);
  const [item_type, setItem_type] = useState(null);
  const [name, setName] = useState("");
  const [idRow, setId] = useState(null);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEditShow = (id) => {
    setId(id);
    setShowEdit(true);
  };

  const handleDeleteShow = (id) => {
    setId(id);
    setShowDelete(true);
  };

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "text.headingColor",
    fontSize: "12px",

    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),

      paddingLeft: `15px`,
      transition: theme.transitions.create("width"),
      maxWidth: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetId) => {
    const draggedId = e.dataTransfer.getData("text/plain");
    const draggedItem = data.find((item) => item.id === Number(draggedId));
    const updatedData = data.filter((item) => item.id !== Number(draggedId));
    const targetIndex = data.findIndex((item) => item.id === Number(targetId));

    updatedData.splice(targetIndex, 0, draggedItem);
    setData(updatedData);
  };

  const user = localStorage.getItem("token");

  const [current_page, setCurrent_page] = useState(1);
  const [countPages, setCountPages] = useState(0);

  const handleChangePagination = (event, value) => {
    console.log(current_page);
    setCurrent_page(value);
    getData(value);
  };

  const getDataOneOnly = async (data) => {
    const res = await Base_Url.get("get_logs", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: {
        page: data,
      },
    });
    if (res.data.logs.data) {
      setData(res.data.logs.data);
      setCurrent_page(res.data.logs.current_page);
      setCountPages(res.data.logs.last_page);
      setData(res.data.logs.data);
    } else {
      setData([]);
    }
  };

  const getData = async (data) => {
    const res = await Base_Url.get("get_logs", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: {
        page: data,
      },
    });
    if (res.data.logs.data) {
      setData(res.data.logs.data);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    getDataOneOnly();
    getDataOptions();
  }, []);

  const [dataFilterCompanies, setDataFilterCompanies] = useState([]);
  const [dataFilterprojects, setDataFilterprojects] = useState([]);
  const [dataFilterBanks, setDataFilterBanks] = useState([]);
  const getDataOptions = async () => {
    const res = await Base_Url.get("data", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    setDataFilterCompanies(res.data.companies);
    setDataFilterprojects(res.data.projects);
    setDataFilterBanks(res.data.banks);
  };

  const getDataFilter = async () => {
    await Base_Url.get("logs_filter", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: {
        item_type: item_type,
        changed_by: changed_by,
        date_from: date_from,
        date_to: date_to,
      },
    })
      .then((res) => {
        if (res.data.logs.length > 0) {
          setData(res.data.logs);
        } else {
          toast.error("No Result Found");
        }
      })
      .catch((error) => {
        toast.error("No Result Found");
      });
  };

  const getDataSearch = async () => {
    await Base_Url.get("logs_filter", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: {
        name: name,
      },
    })
      .then((res) => {
        // console.log(res.data.items);

        if (res.data.logs.length > 0) {
          setData(res.data.logs);
          toast.success("Search Successfully");
          setName("");
        } else {
          toast.error("No Result Found");
        }
      })
      .catch((error) => {
        toast.error("please enter a valid data");
      });
  };

  const handleClear = () => {
    toast.success("Clear Successfully");

    setChanged_by(null);
    setItem_type(null);
    setData([]);
    getDataOneOnly();
  };

  const [date_to, setDateTo] = useState(null);
  const [date_from, setDateFrom] = useState(null);

  const handleDateFromChange = (date) => {
    let dateFrom = new Date(date);

    // Get the year, month, and day from the Date object
    const year = dateFrom.getFullYear();
    const month = String(dateFrom.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0'
    const day = String(dateFrom.getDate()).padStart(2, "0");

    // Create the "yyyy-mm-dd" formatted string
    const formattedDate = `${year}-${month}-${day}`;
    setDateFrom(formattedDate);
  };

  const handleDateToChange = (date) => {
    let dateFrom = new Date(date);

    const year = dateFrom.getFullYear();
    const month = String(dateFrom.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0'
    const day = String(dateFrom.getDate()).padStart(2, "0");

    // Create the "yyyy-mm-dd" formatted string
    const formattedDate = `${year}-${month}-${day}`;
    setDateTo(formattedDate);
  };

  return (
    <Box>
      <Box sx={{ marginTop: "20px", display: "flex" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "43.58px",
            color: "#011E36",
          }}
        >
          Logs
        </Typography>
      </Box>

      <Box>
        <Box sx={{ marginTop: "50px" }}>
          <Box sx={{ position: "relative" }}>
            <div className="input-wrapper" style={{ width: "330px" }}>
              <FaSearch id="search-icon" />
              <input
                placeholder="Type to search..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <Button
              type="submit"
              onClick={() => getDataSearch()}
              sx={{
                textTransform: "capitalize",
                position: "absolute",
                top: "2px",
                left: { xl: "260px", lg: "260px", md: "280px", xs: "280px" },
              }}
            >
              search
            </Button>
          </Box>
        </Box>

        <Box sx={{ marginTop: "50px" }}>
          <Box
            sx={{
              width: { xs: "350px", lg: "920px", xl: "950px", md: "600px" },
              height: { xs: "450px", lg: "85px", xl: "85px", md: "190px" },
              backgroundColor: "#F2F8FF",
              borderRadius: "8px",
              display: "flex",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
                <Box sx={{ marginTop: "22px", marginLeft: "10px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="datePickerEditStyle"
                      sx={{
                        height: "40px",
                        width: "110px",
                        paddingBottom: "40px",
                      }}
                      label="Date From"
                      onChange={handleDateFromChange}
                      value={date_from}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} xl={1.5} lg={1.5}>
                <Box sx={{ marginTop: "22px", marginLeft: "15px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="datePickerEditStyle"
                      label="Date To"
                      sx={{ height: "40px", paddingTop: "0", width: "110px" }}
                      onChange={handleDateToChange}
                      value={date_to}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>

              <Grid item sx={1.5} lg={1.5}>
                <Box>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: "115px",
                      marginTop: "22px",
                      borderRadius: "8px",
                      minheight: "32px",
                      marginLeft: "20px",
                    }}
                    size="small"
                  >
                    <InputLabel
                      id="demo-select-small-label"
                      sx={{
                        fontSize: "12px",
                        marginTop: "3px",
                        color: "rgba(69, 94, 116, 1)",
                      }}
                    >
                      Changed By
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="company_id"
                      name="company_id"
                      value={changed_by || ""}
                      label="Company"
                      onChange={(e) => setChanged_by(e.target.value)}
                    >
                      <MenuItem value="ai">Ai</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item sx={1.5} lg={1.5}>
                <Box>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: "115px",
                      marginTop: "22px",
                      borderRadius: "8px",
                      minheight: "32px",
                      marginLeft: "30px",
                    }}
                    size="small"
                  >
                    <InputLabel
                      id="demo-select-small-label"
                      sx={{
                        fontSize: "12px",
                        marginTop: "3px",
                        color: "rgba(69, 94, 116, 1)",
                      }}
                    >
                      Item Type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="changed_by"
                      name="changed_by"
                      value={item_type || ""}
                      label="changed_by"
                      onChange={(e) => setItem_type(e.target.value)}
                    >
                      <MenuItem value="once">Once</MenuItem>
                      <MenuItem value="monthly">monthly</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Box
                sx={{
                  marginLeft: { xs: "30px", lg: "80px", xl: "90px" },
                  marginTop: { xs: "22px", xl: "40px", lg: "40px" },
                  display: "flex",
                }}
              >
                <Grid item sx={3}>
                  <Button
                    variant="contained"
                    onClick={() => handleClear()}
                    type="submit"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "16.34px",
                      color: "rgba(69, 94, 116, 1)",
                      backgroundColor: "rgba(218, 226, 255, 1)",
                      width: "104px",
                      height: "32px",
                      borderRadius: "8px",
                      padding: "8px, 16px, 8px, 16px",
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>

                <Grid item sx={3}>
                  <Button
                    variant="contained"
                    onClick={() => getDataFilter()}
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "16.34px",
                      color: "rgba(218, 226, 255, 1)",
                      backgroundColor: "rgba(69, 94, 116, 1)",
                      width: "104px",
                      height: "32px",
                      borderRadius: "8px",
                      padding: "8px, 16px, 8px, 16px",
                      marginLeft: {
                        xl: "12px",
                        lg: "40px",
                        md: "12px",
                        xs: "12px",
                      },
                    }}
                  >
                    Apply Filters
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Box>

        <TableContainer sx={{ marginTop: "30px" }}>
          <Table
            sx={{
              maxWidth: { xs: "200px", lg: "920px", xl: "950px", md: "600px" },
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow sx={{ color: "rgba(124, 124, 124, 1)" }}>
                <StyledTableCell>Item ID</StyledTableCell>
                <StyledTableCell>Item Name</StyledTableCell>
                <StyledTableCell>Changed Value</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Changed By</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <StyledTableRow
                  sx={{
                    backgroundColor: `${
                      item.status === "paid" ? "rgba(33, 150, 243, 1)" : ""
                    }`,
                  }}
                  draggable
                  onDragStart={(e) => handleDragStart(e, item.id)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, item.id)}
                  key={item.id}
                >
                  <StyledTableCell component="th" scope="row">
                    {item.item_id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item.name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.value > 150 ? (
                      <img src={img1} alt="" srcset="" />
                    ) : (
                      <img src={img2} alt="" />
                    )}{" "}
                    {item.value}
                  </StyledTableCell>
                  <StyledTableCell>{item.date}</StyledTableCell>
                  <StyledTableCell>{item.changedBy}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            marginY: "30px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Pagination
            count={countPages}
            shape="circular"
            page={current_page}
            onChange={handleChangePagination}
            color="primary"
          />
        </Box>

        {showEdit && (
          <EditDashboard
            show={showEdit}
            setShow={setShowEdit}
            id={idRow}
            companies={setDataFilterCompanies}
            projects={setDataFilterprojects}
            banks={setDataFilterBanks}
          />
        )}
        {show && <DashboardAddTable show={show} setShow={setShow} />}
        {showDelete && (
          <DeleteDashboard
            show={showDelete}
            setShow={setShowDelete}
            id={idRow}
          />
        )}
      </Box>
    </Box>
  );
};

export default Logs;
