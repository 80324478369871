import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "../../assets/icons/dots.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import img3 from "../../assets/icons/Vector (9).png";
import img4 from "../../assets/icons/Vector (10).png";
import img5 from "../../assets/icons/Cash-icon.png";
import AddCash from "./AddCash";
import EditCash from "./EditCash";
import DeleteCash from "./DeleteCash";
import CheckCash from "./CheckCash";
import img7 from "../../assets/icons/Vector (13).png";
import Base_Url from "../../ApiUrl/ApiUrl";

const Cash = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [BankId, setBankId] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setBankId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (id) => {
    setShowEdit(true);
  };

  const handleDelete = (id) => {
    setShowDelete(true);
  };

  const handleCheck = (id) => {
    setShowCheck(true);
  };
  const [data, setData] = useState([]);
  const user = localStorage.getItem("token");

  const getData = async () => {
    await Base_Url.get("cashes", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res) => setData(res.data));
  };

  useEffect(() => {
    getData();
  }, []);

  // handle access
  const userRole = JSON.parse(localStorage.getItem("CashFlow"));

  return (
    <Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "43.58px",
            color: "#011E36",
          }}
        >
          Cash
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {data?.map((item) => (
          <Grid item sx={12} xl={3} lg={4} md={6} key={item.id}>
            <Box
              sx={{
                width: "240px",
                height: "200px",
                backgroundColor: "rgba(255, 255, 255, 1)",
                boxShadow: "1",
                borderRadius: "8px",
                padding: "32px, 48px, 32px, 48px",
                marginTop: "30px",
              }}
            >
              <Box
                sx={{
                  alignItems: "end",
                  justifyContent: "end",
                  display: "flex",
                  marginRight: "5px",
                  paddingTop: "20px",
                }}
              >
                {userRole === "super_admin" || userRole === "admin" ? (
                  <Box>
                    <Button
                      id="fade-button"
                      aria-controls={open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(e) => handleClick(e, item.id)}
                    >
                      <img src={img} alt="" srcset="" />
                    </Button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem onClick={() => handleEdit(item.id)}>
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ position: "relative" }}>
                            <Box
                              sx={{
                                width: "15.82px",
                                height: "15.82px",
                                borderRadius: "50px",
                                backgroundColor: "rgba(181, 199, 70, 1)",
                              }}
                            ></Box>
                            <Box
                              sx={{
                                position: "absolute",
                                top: "-4px",
                                left: "1px",
                              }}
                            >
                              <img src={img3} alt="" srcset="" />
                            </Box>
                          </Box>

                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "19.07px",
                              color: "#455E74",
                              marginLeft: "8px",
                            }}
                          >
                            Edit Cash
                          </Typography>
                        </Box>
                      </MenuItem>

                      <MenuItem onClick={() => handleDelete(item.id)}>
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ position: "relative" }}>
                            <Box
                              sx={{
                                width: "15.82px",
                                height: "15.82px",
                                borderRadius: "50px",
                                backgroundColor: "rgba(243, 66, 53, 1)",
                              }}
                            ></Box>
                            <Box
                              sx={{
                                position: "absolute",
                                top: "-4px",
                                left: "3px",
                              }}
                            >
                              <img src={img4} alt="" srcset="" />
                            </Box>
                          </Box>

                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "19.07px",
                              color: "#455E74",
                              marginLeft: "8px",
                            }}
                          >
                            Delete Cash
                          </Typography>
                        </Box>
                      </MenuItem>

                      <MenuItem onClick={() => handleCheck(item.id)}>
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ position: "relative" }}>
                            <Box
                              sx={{
                                width: "15.82px",
                                height: "15.82px",
                                borderRadius: "50px",
                                backgroundColor: "rgba(255, 167, 38, 1)",
                              }}
                            ></Box>
                            <Box
                              sx={{
                                position: "absolute",
                                top: "-4px",
                                left: "3px",
                                width: "9.17px",
                                height: "9.17px",
                              }}
                            >
                              <img
                                style={{ width: "9.17px", height: "9.17px" }}
                                src={img5}
                                alt=""
                                srcset=""
                              />
                            </Box>
                          </Box>

                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "19.07px",
                              color: "#455E74",
                              marginLeft: "8px",
                            }}
                          >
                            Check Cash Balance
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Menu>
                  </Box>
                ) : null}
              </Box>

              <Box
                sx={{
                  width: "48px",
                  height: "48px",
                  marginTop: "0px",
                  alignItems: "center",
                  marginLeft: "100px",
                }}
              >
                <img src={img5} alt="" srcset="" />
              </Box>

              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "24.51px",
                    color: "rgba(1, 30, 54, 1)",
                    marginTop: "15px",
                  }}
                >
                  {item.name}{" "}
                </Typography>
              </Box>

              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "29.96px",
                    color: "rgba(0, 171, 58, 1)",
                    marginTop: "15px",
                    marginLeft: "0px",
                  }}
                >
                  {item.account_number}{" "}
                </Typography>
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "21,79px",
                  color: "rgba(243, 66, 53, 1)",
                  marginTop: "0px",
                  marginLeft: "80px",
                }}
              >
                {item.current_balance} SAR
              </Typography>
            </Box>
          </Grid>
        ))}

        {userRole === "super_admin" || userRole === "admin" ? (
          <Box sx={{ marginTop: "40px" }}>
            <Button onClick={() => setShowAdd(true)}>
              {" "}
              <Box
                sx={{
                  width: "256px",
                  height: "200px",
                  boxShadow: "1",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "48px",
                    height: "48px",
                    backgroundColor: "#DAE2FF",
                    borderRadius: "50px",
                    marginLeft: "100px",
                    position: "absolute",
                    top: "70px",
                  }}
                >
                  <img
                    src={img7}
                    style={{ marginTop: "12px", marginLeft: "1px" }}
                    alt=""
                    srcset=""
                  />
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#455E74",
                    lineHeight: "19,07px",
                    textTransform: "capitalize",
                    position: "absolute",
                    top: "140px",
                    left: "80px",
                  }}
                >
                  Add New Cash
                </Typography>
              </Box>
            </Button>
          </Box>
        ) : null}
      </Grid>

      {showEdit && (
        <EditCash
          setShowEdit={setShowEdit}
          showEdit={showEdit}
          BankId={BankId}
          getDataBank={getData}
          handleCloseMenu={handleClose}
        />
      )}
      {showAdd && (
        <AddCash setShowAdd={setShowAdd} showAdd={showAdd} getData={getData} />
      )}
      {showDelete && (
        <DeleteCash
          setShowDelete={setShowDelete}
          showDelete={showDelete}
          BankId={BankId}
          getData={getData}
          handleCloseMenu={handleClose}
        />
      )}
      {showCheck && (
        <CheckCash
          setShowCheck={setShowCheck}
          showCheck={showCheck}
          id={BankId}
          getDataBank={getData}
          handleCloseMenu={handleClose}
        />
      )}
    </Box>
  );
};

export default Cash;
