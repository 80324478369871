import styled from '@emotion/styled';
import { Backdrop, Box, Button, Modal, Typography } from '@mui/material'
import img from "../../assets/icons/Vector (15).png"
import img1 from "../../assets/icons/Vector (14).png"

import React from 'react'
import Base_Url from '../../ApiUrl/ApiUrl';
import { toast } from 'react-toastify';
import {  useNavigate } from 'react-router-dom'


const DeleteCompanies = ({setShowDelete, companyId, getData, handleCloseMenu}) => {

  const navigate= useNavigate()
  const user= localStorage.getItem('token')

  

  const handleDelete=(companyId)=>{
 Base_Url.delete(`companies/${companyId}`, {
  headers:{
    Authorization:`Bearer ${user}`
  }
}).then((res)=>{
 

  toast.success('Company Deleted Successfully')
  getData()
  setShowDelete(false)
  handleCloseMenu()
}).catch((error)=>{
  toast.error('Failed Delete')
})
  }
  const handleClose = () => setShowDelete(false);

console.log(companyId);
  return (
    <div>
    <StyledModal
aria-labelledby="unstyled-modal-title"
aria-describedby="unstyled-modal-description"
open={setShowDelete}
onClose={handleClose}
slots={{ backdrop: StyledBackdrop }}
>
<Box sx={style}>
<Box sx={{display:'flex',marginTop:'30px' , justifyContent:'space-between', alignItems:'center'}}>
<Box sx={{display:'flex'}}>
<Button sx={{width:'24px', height:'24px'}}><img src={img} alt=''/></Button>

<Typography variant='h5' sx={{fontSize:'20px', fontWeight:'700', lineHeight:'27,24px', color:'#011E36'}}> Delete Company</Typography>

</Box>
<Button onClick={handleClose} sx={{width:'24px', height:'24px'}}><img src={img1} alt=''/></Button>

</Box>



<Box sx={{marginTop:'40px', width:'470px', height:'57px'}}>

<Typography variant='h5' sx={{fontSize:'16px', fontWeight:'400', lineHeight:'24,39px', color:'rgba(69, 94, 116, 1)', marginLeft:"60px"}}>Are you sure you want to delete this company. Delete this company will delete also all projects and items under this company.</Typography>

</Box>



<Box sx={{marginLeft:'110px', marginTop:'40px'}}>
<Button variant="contained" onClick={handleClose}  sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(117, 119, 120, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', textTransform:"capitalize"}}>Cancel</Button>
<Button variant="contained" type='submit' sx={{width:'210px', height:'43px', borderRadius:'8px', padding:"16px, 32px, 16px, 32px", backgroundColor:'rgba(226, 87, 76, 1)', color:'rgba(255, 255, 255, 1)', boxShadow:'1', marginLeft:'20px', textTransform:"capitalize"}} onClick={()=>handleDelete(companyId)}>Delete Company</Button>

</Box>








</Box>
</StyledModal>
</div>
  )
}

export default DeleteCompanies







const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "589px",
  height:"337px",
  borderRadius: '8px',
  // padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});