import React from 'react'
import Users from '../components/Users/Users'

const UsersPage = () => {
  return (
<Users/>
  )
}

export default UsersPage
