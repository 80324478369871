import { Box } from '@mui/material'
import React from 'react'
import Logs from '../components/Logs/Logs'

const LogsPage = () => {
  return (
<Box>
  <Logs/>
</Box>
  )
}

export default LogsPage
