import React from 'react'
import icon from '../images/icon4.svg'
import icon1 from '../images/icon44.svg'

const IconMouth = () => {
  return (
    <div style={{position:"relative"}}>
        <img src={icon} alt=''/>
        <img src={icon1} style={{position:"absolute", top:"15px", left:"13px"}} alt=''/>
      
    </div>
  )
}

export default IconMouth
