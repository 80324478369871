import styled from "@emotion/styled";
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "../../assets/icons/Vector (14).png";
import { useFormik } from "formik";
import * as Yup from "yup";
import Base_Url from "../../ApiUrl/ApiUrl";
import { toast } from "react-toastify";
import { Radio } from "@mui/joy";

const DashboardAddTable = ({ setShow, show, getData }) => {
  const handleClose = () => setShow(false);
  const user = localStorage.getItem("token");

  // handle radio
  const [selectedValue, setSelectedValue] = React.useState("bank");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  console.log("Bank or Cash", selectedValue);

  const [dataFilterCompanies, setDataFilterCompanies] = useState([]);
  const [dataFilterprojects, setDataFilterprojects] = useState([]);
  // const [selectedCompanies, setSelectedCompanies] = useState('');
  // const [selectedProject, setSelectedProject] = useState('');

  const [idProject, setIdProject] = useState(1);
  const [idCompany, setIdCompany] = useState(1);
  const [idBank, setIdBank] = useState(1);

  const currentDate = new Date();

  // Get individual date components
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based months
  const year = currentDate.getFullYear();

  // Format the date as desired (e.g., "MM/DD/YYYY" or "YYYY-MM-DD")
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const [dataFilterBanks, setDataFilterBanks] = useState([]);
  const [dataFilterCashes, setDataFilterCashes] = useState([]);

  const getDataBanks = async () => {
    await Base_Url.get("banks", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res) => setDataFilterBanks(res.data));
  };

  const getDataCashes = async () => {
    await Base_Url.get("cashes", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res) => setDataFilterCashes(res.data));
  };

  useEffect(() => {
    getDataBanks();
    getDataCashes();
  }, []);

  const getDataOptions = async () => {
    const res = await Base_Url.get("data", {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    setDataFilterCompanies(res.data.companies);
    setIdCompany(res.data.companies[0]?.id);

    // setDataFilterprojects(res.data.projects)
    // setDataFilterBanks(res.data.banks)
    // setIdBank(res.data.banks[0].id)
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      date: formattedDate,
      type: "once",
      value: "",
      priority_level: "medium",
      max_period: 3,
      description: "",
      comment: "",
      transactionable_id: 1,
      company_id: idCompany,
      project_id: idProject,
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .max(20, "Item Name must be 4 characters or more.")
        .required("Item Name is required"),
      comment: Yup.string().min(
        5,
        "Comment Name must be 5 characters or more."
      ),

      description: Yup.string().min(
        10,
        "Description Name must be 10 characters or more."
      ),

      date: Yup.string().required("Date is required"),
      type: Yup.string().required("Item Type is required"),
      value: Yup.string().required("Item Value is required"),
      priority_level: Yup.string().required("Priority Level is required"),
      max_period: Yup.string().required("Maximum Period is required"),
      project_id: Yup.string().required("project  is required"),
      company_id: Yup.string().required("Company is required"),
      transactionable_id: Yup.string().required(
        " Account Bank Or Cash is required"
      ),
    }),
    onSubmit: (values) => {
      const formData = new FormData();

      formData.append("comment", values.comment);
      formData.append("company_id", values.company_id);
      formData.append("date", values.date);
      formData.append("description", values.description);
      formData.append("max_period", values.max_period);
      formData.append("name", values.name);
      formData.append("priority_level", values.priority_level);
      formData.append("project_id", values.project_id);
      formData.append("type", values.type);
      formData.append("value", values.value);
      formData.append("transactionable_id", values.transactionable_id);
      formData.append("transactionable", selectedValue);

      Base_Url.post("items", formData, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
        .then((res) => {
          if (res.data) {
            toast.success("Item Added Successfully", { autoClose: 500 });
            getData();
            setShow(false);
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.message}`);
        });
    },
  });

  useEffect(() => {
    getDataOptions();
  }, []);

  const getProject = async (id) => {
    const res = await Base_Url.get(`companies/${id}`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    console.log(res.data);
    setDataFilterprojects(res.data.projects);
    setIdProject(res.data.projects[0].id);
  };

  useEffect(() => {
    if (formik.values.company_id) {
      getProject(formik.values.company_id);
    }
  }, [formik.values.company_id]);

  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={show}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={{ width: "900px", overflowY: "auto", whiteSpace: "nowrap" }}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "12px",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "20px",
                  fontWeight: "700",
                  lineHeight: "27,24px",
                  color: "#011E36",
                }}
              >
                Add New Item
              </Typography>
              <Button
                onClick={handleClose}
                sx={{ width: "24px", height: "24px" }}
              >
                <img src={img} alt="" />
              </Button>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item lg="6" xl="6" xs="6" md="6">
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Item Name
                    </Typography>
                    <Box
                      sx={{
                        width: "400px",
                        height: "72px",
                        padding: "3px, 5px,3px, 5px",
                        border: "1px solid gba(189, 189, 189, 1) ",
                        borderRadius: "8px",
                      }}
                    >
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        className="datePickerEditStyle"
                        sx={{ marginTop: "10px" }}
                        fullWidth
                        label="Enter your item name"
                        id="name"
                        name="name"
                      />
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {formik.touched.name && formik.errors.name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginTop: "10px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Item Date
                    </Typography>
                    <Box
                      sx={{
                        width: "400px",
                        height: "72px",
                        padding: "16px, 24px, 16px, 24px",
                        border: "1px solid gba(189, 189, 189, 1) ",
                        borderRadius: "8px",
                      }}
                    >
                      <TextField
                        type="date"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.date}
                        className="datePickerEditStyle"
                        sx={{ marginTop: "10px" }}
                        fullWidth
                        id="date"
                        name="date"
                      />
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {formik.touched.date && formik.errors.date}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginTop: "1px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Date Type
                    </Typography>
                    <FormControl
                      sx={{
                        width: "400px",
                        height: "48px",
                        borderRadius: "8px",
                        marginTop: "12px",
                      }}
                      className="datePickerEditStyle"
                    >
                      <InputLabel id="select-label">Just Once</InputLabel>
                      <Select
                        labelId="select-label"
                        id="type"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Just Type..."
                        name="type"
                      >
                        <MenuItem value="once">Once</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.type && formik.errors.type ? (
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {formik.errors.type}{" "}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Box sx={{ marginTop: "20px", display: "flex" }}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Radio
                        checked={selectedValue === "bank"}
                        onChange={handleChange}
                        value="bank"
                        name="radio-buttons"
                        slotProps={{ input: { "aria-label": "Bank" } }}
                        label="Bank"
                      />
                      <Radio
                        checked={selectedValue === "cash"}
                        onChange={handleChange}
                        value="cash"
                        name="radio-buttons"
                        slotProps={{ input: { "aria-label": "Cash" } }}
                        label="Cash"
                      />
                    </Box>
                  </Box>

                  {selectedValue === "bank" && (
                    <Box sx={{ marginTop: "15px" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "19,07px",
                          color: "rgba(69, 94, 116, 1)",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Bank Account
                      </Typography>
                      <FormControl
                        sx={{
                          width: "400px",
                          height: "48px",
                          borderRadius: "8px",
                          marginTop: "12px",
                        }}
                        className="datePickerEditStyle"
                      >
                        <InputLabel id="select-label">Bank Account</InputLabel>
                        <Select
                          labelId="select-label"
                          value={formik.values.transactionable_id}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Bank Account"
                          id="transactionable_id"
                          name="transactionable_id"
                        >
                          {/* <MenuItem value={idBank}></MenuItem> */}

                          {/* <MenuItem  value={dataFilterBanks[0]?.id}>{dataFilterBanks[0]?.name}</MenuItem> */}

                          {dataFilterBanks?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.transactionable_id &&
                      formik.errors.transactionable_id ? (
                        <Typography
                          component="p"
                          sx={{
                            fontSize: "10px",
                            lineHeight: "19,07px",
                            color: "#ff0000",
                            fontWeight: "700",
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          {formik.errors.transactionable_id}{" "}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Box>
                  )}

                  {selectedValue === "cash" && (
                    <Box sx={{ marginTop: "15px" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "19,07px",
                          color: "rgba(69, 94, 116, 1)",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Cash Account
                      </Typography>
                      <FormControl
                        sx={{
                          width: "400px",
                          height: "48px",
                          borderRadius: "8px",
                          marginTop: "12px",
                        }}
                        className="datePickerEditStyle"
                      >
                        <InputLabel id="select-label">Cash Account</InputLabel>
                        <Select
                          labelId="select-label"
                          value={formik.values.transactionable_id}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Cash Account"
                          id="transactionable_id"
                          name="transactionable_id"
                        >
                          {/* <MenuItem  value={idBank}></MenuItem> */}


                          {dataFilterCashes?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.transactionable_id &&
                      formik.errors.transactionable_id ? (
                        <Typography
                          component="p"
                          sx={{
                            fontSize: "10px",
                            lineHeight: "19,07px",
                            color: "#ff0000",
                            fontWeight: "700",
                            marginTop: "-1px",
                          }}
                        >
                          {" "}
                          {formik.errors.transactionable_id}{" "}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Box>
                  )}

                  <Box sx={{ marginTop: "10px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Comment
                    </Typography>
                    <Box
                      sx={{
                        width: "400px",
                        height: "143",
                        padding: "20px, 24px, 20px, 24px",
                        border: "1px solid gba(189, 189, 189, 1) ",
                        borderRadius: "8px",
                      }}
                    >
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.comment}
                        className="commentStyle"
                        sx={{
                          width: "400px",
                          height: "143px",
                          marginTop: "10px",
                          paddingTop: "5px",
                        }}
                        id="comment"
                        label="Add comment"
                        multiline
                        rows={4}
                        defaultValue="Default Value"
                        name="comment"
                      />
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {formik.touched.comment && formik.errors.comment}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg="6" xl="6" xs="6" md="6">
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      Item Value
                    </Typography>
                    <Box
                      sx={{
                        width: "400px",
                        height: "72px",
                        padding: "16px, 24px, 16px, 24px",
                        border: "1px solid gba(189, 189, 189, 1) ",
                        borderRadius: "8px",
                      }}
                    >
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.value}
                        className="datePickerEditStyle"
                        sx={{ marginTop: "10px" }}
                        fullWidth
                        label="$  Enter item’s value"
                        id="value"
                        name="value"
                      />
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "15px",
                        }}
                      >
                        {" "}
                        {formik.touched.value && formik.errors.value}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginTop: "5px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Company
                    </Typography>
                    <FormControl
                      sx={{
                        width: "400px",
                        height: "48px",
                        borderRadius: "8px",
                        marginTop: "7px",
                      }}
                      className="datePickerEditStyle"
                    >
                      <InputLabel id="select-label"> Company</InputLabel>
                      <Select
                        labelId="select-label"
                        id="company_id"
                        value={formik.values.company_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Company"
                        name="company_id"
                      >
                        <MenuItem value={idCompany}></MenuItem>
                        {dataFilterCompanies?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}{" "}
                      </Select>
                    </FormControl>
                    {formik.touched.company_id && formik.errors.company_id ? (
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "7px",
                        }}
                      >
                        {" "}
                        {formik.errors.company_id}{" "}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Box sx={{ marginTop: "5px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Project
                    </Typography>
                    <FormControl
                      sx={{
                        width: "400px",
                        height: "48px",
                        borderRadius: "8px",
                        marginTop: "7px",
                      }}
                      className="datePickerEditStyle"
                    >
                      <InputLabel id="select-label"> Project</InputLabel>
                      <Select
                        labelId="select-label"
                        id="project_id"
                        value={formik.values.project_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Project"
                        name="project_id"
                      >
                        <MenuItem value={idProject}></MenuItem>
                        {dataFilterprojects?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.touched.project_id && formik.errors.project_id ? (
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "7px",
                        }}
                      >
                        {" "}
                        {formik.errors.project_id}{" "}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Box sx={{ marginTop: "5px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Priority Level
                    </Typography>
                    <FormControl
                      sx={{
                        width: "400px",
                        height: "48px",
                        borderRadius: "8px",
                        marginTop: "12px",
                      }}
                      className="datePickerEditStyle"
                    >
                      <InputLabel id="select-label"> Priority Level</InputLabel>
                      <Select
                        labelId="select-label"
                        id="priority_level"
                        value={formik.values.priority_level}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Priority Level"
                        name="priority_level"
                      >
                        <MenuItem value="medium">Medium</MenuItem>
                        <MenuItem value="low">Low</MenuItem>
                        <MenuItem value="high">High</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.priority_level &&
                    formik.errors.priority_level ? (
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "7px",
                        }}
                      >
                        {" "}
                        {formik.errors.priority_level}{" "}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Box sx={{ marginTop: "5px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Description
                    </Typography>
                    <Box
                      sx={{
                        width: "400px",
                        height: "72px",
                        padding: "16px, 24px, 16px, 24px",
                        border: "1px solid gba(189, 189, 189, 1) ",
                        borderRadius: "8px",
                      }}
                    >
                      <TextField
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                        className="datePickerEditStyle"
                        sx={{ marginTop: "10px" }}
                        fullWidth
                        label="Add description"
                        id="description"
                        name="description"
                      />
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "15px",
                        }}
                      >
                        {" "}
                        {formik.touched.description &&
                          formik.errors.description}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginTop: "10px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "19,07px",
                        color: "rgba(69, 94, 116, 1)",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Maximum Period
                    </Typography>
                    <FormControl
                      sx={{
                        width: "400px",
                        height: "48px",
                        borderRadius: "8px",
                        marginTop: "12px",
                      }}
                      className="datePickerEditStyle"
                    >
                      <InputLabel id="select-label">7 Days</InputLabel>
                      <Select
                        labelId="select-label"
                        id="max_period"
                        value={formik.values.max_period}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="7 Bays"
                        name="max_period"
                      >
                        <MenuItem value="1">1 Day</MenuItem>
                        <MenuItem value="2">2 Day</MenuItem>
                        <MenuItem value="3">3 Day</MenuItem>
                        <MenuItem value="4">4 Day</MenuItem>
                        <MenuItem value="5">5 Day</MenuItem>
                        <MenuItem value="6">6 Day</MenuItem>
                        <MenuItem value="7">7 Day</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.max_period && formik.errors.max_period ? (
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "10px",
                          lineHeight: "19,07px",
                          color: "#ff0000",
                          fontWeight: "700",
                          marginTop: "7px",
                        }}
                      >
                        {" "}
                        {formik.errors.max_period}{" "}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{ marginLeft: "440px", marginTop: "20px", display: "flex" }}
              >
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{
                    width: "210px",
                    height: "43px",
                    borderRadius: "8px",
                    padding: "16px, 32px, 16px, 32px",
                    backgroundColor: "rgba(117, 119, 120, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    boxShadow: "1",
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "210px",
                    height: "43px",
                    borderRadius: "8px",
                    padding: "16px, 32px, 16px, 32px",
                    backgroundColor: "rgba(1, 30, 54, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    boxShadow: "1",
                    marginLeft: "20px",
                    textTransform: "capitalize",
                  }}
                >
                  Add New Item
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
};

export default DashboardAddTable;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  height: 100%;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: { xl: "896px", lg: "896px" },
  height: { xl: "499px", lg: "499px" },
  borderRadius: "8px",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === "dark" ? "#000" : "#383838"
  }`,
  overflowY: "auto",
});
